import { Project } from 'views/project/project'
import { ShareProject } from 'views/project/shareProject'

const Routes = [
  {
    path: '/project/:id',
    view: Project,
    layout: 'app',
    title: 'Project',
  },
  {
    path: '/project/:id/:tag',
    view: Project,
    layout: 'app',
    title: 'Project',
  },
  {
    path: '/project/share/:accessLink',
    view: ShareProject,
    layout: 'app',
    title: 'Project',
  },
  {
    path: '/project/share/:accessLink/:tag',
    view: ShareProject,
    layout: 'app',
    title: 'Project',
  },
]

export default Routes
