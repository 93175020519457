/***
 *
 *   useVerifiedFiltersParam hook
 * 
 **********/

import _React, {
  useState,
  useEffect,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAPI } from 'components/lib'

import { useBasicDappsData } from 'features/gi/shared/hooks/useBasicDappsData'
import blockchain from 'assets/icons/blockchains.svg'
import dappSymbol from 'assets/icons/dapp-symbol-dark-outline.svg'

export const usePublicDashboardsFilters = () => {
  const [searchParams, _setSearchParams] = useSearchParams()
  const [selectedBlockchain, setSelectedBlockchain] = useState()
  const [blockchainsOptions, setBlockchainsOptions] = useState()
  const [verifiedBlockchainParam, setVerifiedBlockchainParam] = useState<string | undefined>()
  const blockchainParam = searchParams.get('blockchain')
  const dappParam = searchParams.get('dapp')
  const [selectedDapp, setSelectedDapp] = useState()
  const [dappsOptions, setDappsOptions] = useState()
  const [verifiedDappParam, setVerifiedDappParam] = useState<string | undefined>()
  const blockchains = useAPI('api/blockchains?active=true&dappgrowth=true').data
  const { basicDappsData } = useBasicDappsData()
  const allBlockchainsOption = {
    label: 'All Blockchains',
    value: 'all',
    icon: blockchain,
    network: 'Mainnet',
    name: 'All Blockchains',
    id: 'all',
  }
  const allDappsOption = {
    label: 'All Dapps',
    value: 'all',
    icon: dappSymbol,
    name: 'All Dapps',
    id: 'all',
  }

  useEffect(() => {
    if (blockchains) {
      const modifiedBlockchains = blockchains.map(item => {
        return (
          {
            label: item.name,
            value: item.slug,
            icon: item.logo,
            network: item.network || 'Mainnet',
            name: item.name,
            id: item.id,
          }
        )
      })
      setBlockchainsOptions([
        allBlockchainsOption,
        ...modifiedBlockchains,
      ])

      const commonBlockchains = blockchains.filter(blockchain => blockchain.slug === blockchainParam?.toLowerCase())
      if (commonBlockchains.length > 0) {
        setVerifiedBlockchainParam(blockchainParam)
        setSelectedBlockchain(commonBlockchains[0])
      } else {
        setVerifiedBlockchainParam(undefined)
        setSelectedBlockchain({ ...allBlockchainsOption, slug: 'all'})
      }
    }

    if (basicDappsData?.length > 0) {
      const modifiedDapps = basicDappsData.map(item => {
        return (
          {
            label: item.name,
            value: item.slug,
            icon: item.icon,
            name: item.name,
            id: item.id,
          }
        )
      })
      setDappsOptions([
        allDappsOption,
        ...modifiedDapps,
      ])

      const commonDapps = basicDappsData.filter(dapp => dapp.slug === dappParam?.toLowerCase())
      if (commonDapps.length > 0) {
        setVerifiedDappParam(dappParam)
        setSelectedDapp(commonDapps[0])
      } else {
        setVerifiedDappParam(undefined)
        setSelectedDapp({ ...allDappsOption, slug: 'all'})
      }
    }

  }, [
    blockchains,
    blockchainParam,
    basicDappsData,
    dappParam
  ])

  return {
    selectedBlockchain: selectedBlockchain || { ...allBlockchainsOption, slug: 'all'},
    verifiedBlockchainParam: verifiedBlockchainParam || 'all',
    blockchainsOptions: blockchainsOptions,
    dappsOptions: dappsOptions,
    verifiedDappParam: verifiedDappParam,
    selectedDapp: selectedDapp || { ...allDappsOption, slug: 'all'},
  }
}