import React from 'react'
import { ClassHelper } from 'components/lib'
import Style from './title.module.scss'

export const Title = (props) => {
  const titleStyle = ClassHelper(Style, props)
  
  return (
    <h1 className={titleStyle}>{props.children}</h1>
  )
}
