/***
 *
 *   useBasicValuesData hook
 *   for getting single values of basic components growth index data
 * 
 **********/

import {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react'
import axios from 'axios'

import { ViewContext } from 'components/lib'
// import { logger } from 'utils/logger'
import { endpoint } from 'utils/paths'

// TODO add type
// import { BasicValuesSchema } from 'features/tgDashboards/tgBasicDashboard/types/BasicValuesSchema'

export const useGiDappBasicValuesData = (
  verifiedDappSegment,
  selectedDapp,
  selectedDappsToCompare,
  verifiedParamsStr,
  verifiedBlockchainsParam,
  selectedBlockchains
) => {
  const [giDappBasicValuesData, setGiDappBasicValuesData] = useState()
  const [isGiDappBasicValuesDataLoading, setIsGiDappBasicValuesDataLoading] = useState(true)
  const viewContext = useContext(ViewContext)
  const mainDataRequestController = useRef()
  
  useEffect(() => {
    if (verifiedDappSegment && verifiedParamsStr) {
      const fetchBasicValuesData = async () => {
        try {
          if (mainDataRequestController.current) {
            mainDataRequestController.current.abort()
          }

          mainDataRequestController.current = new AbortController()
          const signal = mainDataRequestController.current.signal
          setIsGiDappBasicValuesDataLoading(true)
          let url = `${endpoint.apiGiDappBasicData}`
          let compare = []
          if (selectedDappsToCompare && selectedDappsToCompare.length > 0) {
            compare = selectedDappsToCompare.map(item => item.dApp.name)
          }

          const postData = {
            dAppName: selectedDapp?.name,
            compareWith: compare,
            blockchainBreakdown: false,
          }

          if (verifiedBlockchainsParam) {
            postData.chains = selectedBlockchains.map(blockchain => blockchain.slug)
          }
          const response = await axios.post(url, postData, {
            signal: signal
          })
          // const validatedBasicValues = BasicValuesSchema.safeParse(response?.data)
          // if (!validatedBasicValues.success) {
          //   logger.error(validatedBasicValues.error)
          //   throw Error('Incorrect server response format')
          // }
          setGiDappBasicValuesData(response.data)
          setIsGiDappBasicValuesDataLoading(false)
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setIsGiDappBasicValuesDataLoading(false)
            viewContext.handleError(err)
          }
        }
      }

      fetchBasicValuesData()
    }

  }, [verifiedDappSegment, verifiedParamsStr, verifiedBlockchainsParam])

  return { giDappBasicValuesData, isGiDappBasicValuesDataLoading }
}