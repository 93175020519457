/***
 *
 *   SOCIAL SHARING BUTTONS
 *   A sharing wiget for Twitter, Telegram and Linkedin
 *
 *   PROPS
 *   url: url of page to share
 *
 **********/

import React from 'react'
import { IconLib, ClassHelper, useMobile } from 'components/lib'
import Style from './social.module.scss'

export function SocialMedia(props) {
  const isMobile = useMobile()
  const networks = {
    twitter: `https://twitter.com/Tokenguard_io`,
    telegram: `https://t.me/tokenguard_io`,
    linkedin: `https://pl.linkedin.com/company/tokenguard-io`,
  }

  const buttonsStyle = ClassHelper(Style, {
    socialMediaButtons: true,
    className: props.className,
  })

  return (
    <div className={buttonsStyle}>
      {Object.keys(networks).map((key, i) => {
        const n = networks[key]
        const css = ClassHelper(Style, {
          [key]: true,
          socialMediaButtons: true,
        })

        return (
          <a className={css} href={n} key={i}>
            <IconLib
              image={isMobile ? `${[key]}Big` : [key]}
              size={isMobile ? 24 : 16}
              pack="own"
              className={Style.icon}
            />
          </a>
        )
      })}
    </div>
  )
}
