/***
 *
 *   DASHBOARD CONTENT CONTEXT
 *
 **********/

import React, {
  useState,
  createContext,
  ReactNode,
} from 'react'

import { TElement } from 'types/dashboard/element'
import { TLayout } from 'types/dashboard/layout'
import { TTheme } from 'types/dashboard/theme'
import { BRAND_NAME } from 'utils/constans'

export interface DashboardContentContextProps {
  dashboardId: string
  setDashboardId: React.Dispatch<React.SetStateAction<string>>
  dashboardTitle: string
  setDashboardTitle: React.Dispatch<React.SetStateAction<string>>
  dashboardDappId: string
  setDashboardDappId: React.Dispatch<React.SetStateAction<string>>
  dashboardElements: Array<TElement>
  setDashboardElements: React.Dispatch<React.SetStateAction<Array<TElement>>>
  dashboardFilters: Array<string>
  setDashboardFilters: React.Dispatch<React.SetStateAction<Array<string>>>
  dashboardLayout: TLayout
  setDashboardLayout: React.Dispatch<React.SetStateAction<TLayout>>
  dashboardTheme: TTheme
  setDashboardTheme: React.Dispatch<React.SetStateAction<TTheme>>
}

export const DashboardContentContext = createContext<DashboardContentContextProps | undefined>(undefined)

interface DashboardContentProviderProps {
  children: ReactNode
}

const initialTheme = {
  name: BRAND_NAME,
  primaryColor: "#48BD98",
  secondaryColor: "#0A425E",
  additionalColor: "#E6A627",
  bgColor: "#E2D5D5",
  itemGridRadius: "6px",
  itemGridBgColor: "#FFFFFF",
  font: "Roboto",
  textColor: "#656565",
  itemGridStroke: "#ECECEC",
  chartGradient: true,
  bottomTimeline: true,
}

const initialLayout = []
const initialElements = []

export function DashboardContentProvider({ children }: DashboardContentProviderProps) {
  const [dashboardTitle, setDashboardTitle] = useState('Default dashboard')
  const [dashboardId, setDashboardId] = useState()
  const [dashboardDappId, setDashboardDappId] = useState()
  const [dashboardElements, setDashboardElements] = useState(initialElements)
  const [dashboardLayout, setDashboardLayout] = useState(initialLayout)
  const [dashboardFilters, setDashboardFilters] = useState([])
  const [dashboardTheme, setDashboardTheme] = useState(initialTheme)

  return (
    <DashboardContentContext.Provider
      value={{
        dashboardTitle,
        setDashboardTitle,
        dashboardId,
        setDashboardId,
        dashboardElements,
        setDashboardElements,
        dashboardLayout,
        setDashboardLayout,
        dashboardFilters,
        setDashboardFilters,
        dashboardTheme,
        setDashboardTheme,
        dashboardDappId,
        setDashboardDappId
      }}
    >
      {children}
    </DashboardContentContext.Provider>
  )
}
