import React, {
  useEffect,
  useState,
} from 'react'
import { toast } from 'react-toastify'

import { fetchResultData } from 'utils/fetches/resultData'
import { ResultSchema } from 'types/result'
import {
  SingleValue,
  CustomPieChart,
  CustomBarChart,
  CustomAreaChart,
  Loader
} from 'components/lib'
import { logger } from 'utils/logger'
import {
  adjustForSingleValue,
  removeUselessProperties,
  calcHeight
} from 'utils/helpers'

import { prepareFiltersBodyRequestFormat } from 'features/dashboard/utils/helpers'
import Style from './LockedVisualization.module.css'

export const LockedVisualization = ({
  element,
  dashboardId,
  filters,
  dashboardTheme,
  elementHeight,
  dappId,
}) => {
  const [data, setData] = useState()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const filtersBodyRequest = filters ? prepareFiltersBodyRequestFormat(filters) : []

  useEffect(() => {
    if (!element?.data) {
      const fetchData = async () => {
        try {
          const elFiltersForRequest = removeUselessProperties(element.filters)
          setIsDataLoading(true)
          const bodyRequest = {
            breakdown: element.breakdown,
            filters: elFiltersForRequest
          }
          const fetchedResultData = await fetchResultData(dappId, element.metric, bodyRequest)
          const validatedResultData = ResultSchema.safeParse(fetchedResultData.output)
          if (!validatedResultData.success) {
            logger.error(validatedResultData.error)
            throw Error('Unable to result data - incorrect response format.')
          }
          setData(validatedResultData.data)
          setIsDataLoading(false)
        } catch (err) {
          setIsDataLoading(false)
          toast.error('Upss.. There was a problem to load data')
        }
      }

      fetchData()
    } else {
      setData(element.data)
    }
  }, [
    element?.type,
    JSON.stringify(filtersBodyRequest),
    dashboardId
  ])

  return (
    <>
      {element?.visType === 'singleValue' ? (
          <SingleValue
            data={adjustForSingleValue(data, 'N/A', element.metric)}
            title={element?.title}
            loading={isDataLoading}
            theme={dashboardTheme}
          />
      ) : (
      <div
        className={Style['grid-item']}
        style={{
          borderRadius: dashboardTheme?.itemGridRadius,
          backgroundColor: dashboardTheme?.itemGridBgColor,
          color: dashboardTheme?.textColor,
          borderColor: dashboardTheme?.itemGridStroke
        }}
      >
        <h3
          className={Style['item-title']}
          style={{
            color: dashboardTheme?.textColor,
            fontFamily: dashboardTheme?.font
          }}
        >
          {element?.title}
        </h3>
        {isDataLoading && (
          <Loader />
        )}
        {!isDataLoading &&
          data &&
          {
            'areaChart': <CustomAreaChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
              locked
            />,
            'lineChart': <CustomAreaChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
              locked
            />,
            'barChart': <CustomBarChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
              locked
            />,
            'pieChart': <CustomPieChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
              locked
            />,
          } [element?.visType]
        }     
      </div>)}
    </>
  )
}
