/***
 *
 *   BUTTON
 *   Can be a standard button, icon button or with loading animation
 *
 *   PROPS
 *   text: button label
 *   action: callback function executed on click
 *   params: object passed to the callback function (optional)
 *   color: red/blue (default: primary)
 *   icon: icon image (optional)
 *   iconPack: icon pack to use
 *   iconSize: icon size
 *   iconButton: true or false
 *   alignIcon: left or right
 *   small: render a smaller button
 *   textOnly: text only
 *   outline: outline button
 *   className: pass a custom class object
 *   fullWidth: extend to full width of parent container
 *   loading: boolean to toggle loading animation (optional)
 *
 **********/

import React, { Fragment } from 'react'
import { IconLib, ClassHelper, useNavigate } from 'components/lib'
import ButtonStyle from './button.module.scss'
import IconStyle from './icon.module.scss'

export function Button(props) {
  const navigate = useNavigate()

  const buttonStyle = ClassHelper(ButtonStyle, {
    ...props,
    ...{
      [props.color]: props.color,
      [props.position || 'relative']: true,
      textOnly: props.textOnly,
      iconButton: props.icon && !props.text,
      btn: props.iconButton || (!props.textOnly && !props.icon),
      ...(!props.color &&
        props.text &&
        !props.color &&
        !props.icon &&
        !props.textOnly &&
        !props.iconOnly &&
        !props.outline && {
          // default color
          primary: true,
        }),
    },
  })

  const iconStyle = ClassHelper(IconStyle, {
    iconLeft: props.alignLeft,
    iconRight: props.alignRight,
    iconMediumPosition: props.iconMediumPosition,
    insideButton: props.iconButton || (!props.textOnly && !props.icon),
  })

  return (
    <button
      title={props.title}
      className={buttonStyle}
      id={props.buttonId}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        props.action && props.action(props.params)
        props.goto && navigate(props.goto)
        if (props.url) window.location = props.url
      }}
    >
      {props.icon ? (
        <Fragment>
          <IconLib
            image={props.icon}
            pack={props.iconPack}
            color={props.iconColor || props.color}
            size={props.iconSize || props.size}
            iconWidth={props.iconSize || props.iconWidth}
            iconHeight={props.iconSize || props.iconHeight}
            className={iconStyle}
          />
          {props.text && props.text}
        </Fragment>
      ) : (
        props.text
      )}
    </button>
  )
}
