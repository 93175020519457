import React from 'react'

import {
  Icon,
  Typography,
} from 'components/lib'

import Style from './RankingBanner.module.css'

type TRankingBannerProps = {
  iconName: string;
  text: string;
  link: string;
}

export const RankingBanner = ({
  iconName,
  text,
  link
}: TRankingBannerProps) => (
  <a
    href={link}
    className={Style['ranking-banner-container']}
  >
    <div className={Style['opacity-cover']}></div>
    <div className={Style.ecosystemBannerContent}>
      <Icon
        name={iconName}
        width="16"
        height="16"
      />
      <Typography
        tag="span"
        text={text}
        color="primary500"
        size="s"
        className="nowrap"
        weight="medium"
      />
    </div>
  </a>
)
