import axios from 'axios'
import { endpoint } from 'utils/paths'
import settings from 'settings'
import { generateUniqueName } from 'utils/helpers'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchNewDashboard = async (username) => {
  const response = await axios.post(`${serverURL}/${endpoint.apiDashboard}`, {
    title: generateUniqueName(username),
  })
  const data = response.data
  const { lookerDashboardData, dashboardData } = data
  return { lookerDashboardData, dashboardData }
}

export const fetchDashboard = async (dashboardId) => {
  const response = await axios.get(
    `${serverURL}/${endpoint.apiDashboard}/${dashboardId}`
  )
  const data = response.data

  return data?.data
}

export const fetchShareDashboard = async (accessLink) => {
  const response = await axios.get(
    `${serverURL}/${endpoint.apiDashboard}/shared/${accessLink}`
  )
  const data = response.data

  return data?.data
}

export const fetchDashboardDataElement = async (dashboardId, formattedElementId, requestBody) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.apiDashboard}/${dashboardId}/element/${formattedElementId}/exec`,
    requestBody
  )

  return response.data
}

export const fetchInvitedDashboardUsers = async (dashboardId) => {
  const response = await axios.get(
    `${serverURL}/api/dashboard/${dashboardId}/users`
  )
  const data = response.data

  return data?.users
}

export const updateDashboard = async (
  databaseDashboardId,
  dashboardTitle,
  accessType
) => {
  const response = await axios.put(
    `${serverURL}/${endpoint.apiDashboard}/${databaseDashboardId}`,
    {
      id: databaseDashboardId,
      title: dashboardTitle,
      accessType: accessType,
    }
  )
  const data = response.data

  return data?.data
}

export const removeDashboard = async (databaseDashboardId) => {
  const response = await axios.delete(
    `${serverURL}/${endpoint.apiDashboard}/${databaseDashboardId}`
  )
  const data = response.data

  return data?.data
}

export const addDashboardElement = async (lookId, dashboardId) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.lookerAddDashboardElement}`,
    {
      dashboardId: dashboardId,
      lookId: lookId,
    }
  )
  const data = response.data?.data

  return data
}

export const removeDashboardElement = async (
  dashboardId,
  lookId,
  dashboardElementId
) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.lookerRemoveDashboardElement}`,
    {
      dashboardElementId: dashboardElementId,
      lookId: lookId,
      dashboardId: dashboardId,
    }
  )
  const data = response.data?.data

  return data
}

export const updateDashboardAccess = async (id, value) => {
  const response = await axios.put(
    `${serverURL}/${endpoint.apiDashboard}/${id}/access`,
    {
      accessType: value,
    }
  )
  const data = response.data?.data

  return data
}

export const removeEmailFromDashboard = async (dashboardId, emailToRemove) => {
  const response = await axios.delete(
    `${serverURL}/${endpoint.apiDashboard}/${dashboardId}/removeEmail`,
    {
      data: {
        email: emailToRemove,
      },
    }
  )
  return response.data?.data
}

export const addEmailsToDashboard = async (dashboardId, validEmails) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.addEmailWithAccessToDashboard}/${dashboardId}`,
    {
      emails: validEmails,
    }
  )
  const data = response.data?.data
  return data
}

export const fetchDashboards = async (param) => {
  const response = await axios.get(
    `${serverURL}/${endpoint.apiDashboards}${param}`,
  )
  const data = response.data?.data
  
  return data
}
