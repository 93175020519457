/***
 * HOOK of rankings nav items
 *
 **********/

import { paths } from 'utils/paths'

export function useNavRankingItems() {

  const navOverviewItems = [
    {
      label: 'dApps',
      icon: 'dapp',
      link: paths.giDappOverview,
      related: [`/app/:dapp/analytics`, `/app/:dapp/charts`, `/:dapp/web3-crm`, `/app/:dapp/web3-crm/:address`],
      disabled: false,
    },
    {
      label: 'Web3 CRM',
      icon: 'web3crm',
      link: '/web3-crm',
      related: [`/app/:dappName/web3-crm`],
      disabled: false,
    },
    {
      label: 'Crypto Activity',
      icon: 'tgDashboards',
      link: paths.giEcosystemOverview,
      related: [paths.giEcosystemOverview, `/crypto/:blockchain`, `/crypto/:blockchain/charts`]
    }
  ]

  return navOverviewItems
}
