/***
 *
 *   CONTENT AUTHORIZATION GRID
 *   include 2 columns of space for auth data
 *
 **********/

import React from 'react'
import Style from './contentAuthGrid.module.scss'

export const ContentAuthGrid = ({ children }) => (
  <div className={Style.grid}>{children}</div>
)
