/***
 *
 *   SOCIAL SIGN IN
 *   After social authentication has been completed on the server
 *   the user is redirected back to this view - which obtains
 *   the token and completes the signin flow as normal
 *
 **********/

import React, { useContext, useEffect, useRef } from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { gtm } from 'utils/analytics/gtm'
import {
  Animate,
  AuthContext,
  ViewContext,
  Loader,
  Message,
  useNavigate,
  useLocation,
} from 'components/lib'

export function SocialSignin() {
  // wrap in useRef to prevent triggering useEffect multiple times
  const authContext = useRef(useContext(AuthContext))
  const viewContext = useRef(useContext(ViewContext))
  const navigate = useNavigate()
  const location = useLocation()

  const qs = location.search
  const provider = qs.substring(qs.indexOf('?provider') + 10, qs.indexOf('&'))
  const token = qs.substring(qs.indexOf('&token') + 7)

  const decodedToken = jwtDecode(token)
  const registered_now = decodedToken.registered_now
  const user_id = decodedToken.user_id
  const email = decodedToken.email
  const privatePathToRedirect = JSON.parse(
    localStorage.getItem('privatePathToRedirect')
  )

  useEffect(() => {
    if (registered_now) {
      gtm.trackSignUp(email, user_id, provider)
    }
  }, [])

  useEffect(() => {
    async function fetchToken() {
      try {
        const res = await axios({
          method: 'post',
          url: '/api/auth',
          data: { token: token },
        })
        res.data['2fa_required']
          ? navigate(`/signin/otp?token=${res.data.token}`)
          : authContext.current.signin(res, privatePathToRedirect)
      } catch (err) {
        viewContext.current.handleError(err)
      }
    }

    if (token) fetchToken()
  }, [token, viewContext, authContext, navigate])

  return (
    <Animate type="pop">
      <div className="align-xy">
        {/* TODO design this section */}
        {/* <h1>
          {`Signing in with ${
            provider.charAt(0).toUpperCase() + provider.slice(1)
          }`}
        </h1> */}
        {token && <Loader />}
        {!token && (
          <div style={{ width: '100%', margin: '0 auto' }}>
            <Message
              type="error"
              title={`Something went wrong`}
              text="Invalid URL, please try in again"
              buttonText="Sign In"
              buttonLink="/signin"
            />
          </div>
        )}
      </div>
    </Animate>
  )
}
