/***
 *
 *   SHARE DASHBOARD
 *   specific dashboard view
 *
 **********/

import React, {
  useState,
  useEffect,
  useContext,
} from 'react'
import { useParams } from 'react-router-dom'

import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'
import {
  Animate,
  ViewContext,
  Loader,
} from 'components/lib'
import { fetchShareDashboard } from 'utils/fetches/dashboard'
import { DashboardSchema } from 'types/dashboard/dashboard'

import { Dashboard } from 'features/dashboard/Dashboard'

export function ShareDashboardPage() {
  const { accessLink } = useParams()
  const [dashboard, setDashboard] = useState()
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false)
  const viewContext = useContext(ViewContext)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingDashboard(true)
        const fetchedDashboardData = await fetchShareDashboard(accessLink)
        const validatedDashboard = DashboardSchema.safeParse(fetchedDashboardData)
        if (!validatedDashboard.success) {
          logger.error(validatedDashboard.error)
          throw Error(getValidationErrorMessage('dashboard'))
        }
        setDashboard(validatedDashboard.data)
        setIsLoadingDashboard(false)
      } catch (err) {
        setIsLoadingDashboard(false)
        viewContext && viewContext.handleError(err)
      }
    }

    fetchData()
  }, [accessLink])

  return (
    <Animate type="pop">
      {!isLoadingDashboard &&
      dashboard && (
        <Dashboard dashboardData={dashboard} />
      )}
      {isLoadingDashboard && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </Animate>
  )
}
