import React from 'react'

import {
  ButtonNew,
  useMobile,
} from 'components/lib'
import { Icon } from 'components/icon/Icon'
import dappSymbol from 'assets/images/dapp-symbol.svg'
import dappSymbolDark from 'assets/images/dapp-symbol-dark.svg'

import Style from './NoDappContent.module.scss'

export const NoDappContent = ({
  setIsDisplayingAddDappModal,
  page
}) => {
  const isMobile = useMobile()

  if (page === 'grid') {
    return (
      <div className={`${Style['no-dapp-grid']}`}>
        <img src={dappSymbolDark} alt="dapp symbol" />
        <p className={Style['text']}>
          Add dApp to continue
          <span className={Style['highlighted-text']}>
            You haven't added any dApps yet. Please upload a {!isMobile && <br />}
            dApp to start building your dashboard.
          </span>
        </p>
        <ButtonNew
          onClick={() => setIsDisplayingAddDappModal(true)}
        >
          Add dApp
        </ButtonNew>
      </div>
    )
  }

  return (
    <div className={Style['no-dapp-add-dapp']}>
      <img src={dappSymbol} alt="dapp symbol" />
      <p className={Style['text']}>
        Add information about your dApp
        <span className={Style['highlighted-text']}>
          This will enable you to access analytics. {!isMobile && (<br />)}
          Your list of added dApps will appear here
        </span>
      </p>
      <ButtonNew
        onClick={() => setIsDisplayingAddDappModal(true)}
        startIcon={<Icon name="plus" width="16" height="16" />}
      >
        Add dApp
      </ButtonNew>
    </div>
  )
}