import React from 'react'
import { ClassHelper } from 'components/lib'
import Style from './label.module.scss'

export function Label(props) {
  const labelStyle = ClassHelper(Style, {
    capitalize: props.capitalize,
    [props.size]: props.size
  })

  return (
    <label className={labelStyle} htmlFor={props.for}>
      {props.text}
    </label>
  )
}
