import { GiDappBasicPage } from 'views/gi/dapp/giDappBasicPage'
import { GiDappBreakdownPage } from 'views/gi/dapp/giDappBreakdownPage'
import { GiDappOverviewPage } from 'views/gi/dapp/giDappOverviewPage'

import { paths } from 'utils/paths'

const Routes = [
  {
    path: paths.giDappOverview,
    view: GiDappOverviewPage,
    layout: 'redesignApp',
    title: 'Top dApps | Patterns',
  },
  {
    path: `/app/:dappName/analytics`,
    view: GiDappBasicPage,
    layout: 'redesignApp',
  },
  {
    path: `/app/:dappName/charts`,
    view: GiDappBreakdownPage,
    layout: 'redesignApp',
  }
]

export default Routes
