import axios from 'axios'
import { endpoint } from 'utils/paths'
import settings from 'settings'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchProject = async (projectId) => {
  const response = await axios.get(
    `${serverURL}/${endpoint.apiProject}/${projectId}`
  )
  const data = response.data

  return data?.data
}

export const deleteProject = async (projectId) => {
  const response = await axios.delete(
    `${serverURL}/${endpoint.apiProject}/${projectId}`
  )
  const data = response.data

  return data?.data
}

export const updateProjectAccess = async (id, value) => {
  const response = await axios.put(
    `${serverURL}/${endpoint.apiProject}/${id}/access`,
    {
      accessType: value,
    }
  )
  const data = response.data?.data

  return data
}

export const fetchShareProject = async (accessLink) => {
  const response = await axios.get(
    `${serverURL}/${endpoint.apiProject}/shared/${accessLink}`
  )
  const data = response.data

  return data?.data
}

export const fetchInvitedProjectUsers = async (projectId) => {
  const response = await axios.get(
    `${serverURL}/api/project/${projectId}/users`
  )
  const data = response.data

  return data?.users
}

export const removeEmailFromProject = async (projectId, emailToRemove) => {
  const response = await axios.delete(
    `${serverURL}/${endpoint.apiProject}/${projectId}/removeEmail`,
    {
      data: {
        email: emailToRemove,
      },
    }
  )
  const data = response.data?.data

  return data
}

export const addEmailsToProject = async (projectId, validEmails) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.addEmailWithAccessToProject}/${projectId}`,
    {
      emails: validEmails,
    }
  )
  const data = response.data?.data

  return data
}
