/***
 *
 *   PASSWORD
 *   Update the users password
 *
 *   if the user has a password show old and new inputs
 *   if no existing password (eg. in case of social signin)
 *   then allow the user to set a password on their account
 *
 **********/

import React, { Fragment, useContext, useState } from 'react'

import {
  AuthContext,
  MainContentContainer,
  Card,
  Form,
  Message,
  useMobile,
  Typography,
  TabBar,
  Breadcrumbs,
  useNavigate
} from 'components/lib'
import { useSeoPage } from 'hooks/useSeoPage'

import { paths } from 'utils/paths'

export function Password(props) {
  const context = useContext(AuthContext)
  const [done, setDone] = useState(false)
  const isMobile = useMobile()
  const navigate = useNavigate()
  const breadcrumbsItems = [
    {
      key: 'home',
      url: paths.giDappOverview,
      name: 'Home',
    },
    {
      key: 'password',
      url: '/account/password',
      name: 'Settings',
    }
  ]
  const tabs = [
    {
      name: 'Profile',
      path: `/account/profile`,
      onClick: () => navigate(`/account/profile`),
    },
    {
      name: 'Password',
      path: `/account/password`,
      onClick: () => navigate(`/account/password`),
    },
  ]
  useSeoPage({
    // title: to set,
    // description: to set,
    // keywords: to set,
    // ogTitle: to set,
    // ogDescription: to set,
    // ogImage: to set,
    // ogUrl: to set
  })

  return (
    <MainContentContainer>
      <Breadcrumbs items={breadcrumbsItems} />
      <Typography
        tag="h1"
        text="Settings"
        size="l"
        color="gray900"
        weight="semibold"
        className="mt24 mb8"
      />
      <TabBar underlyingLine tabs={tabs} />
        <div style={isMobile ? { marginTop: '24px' } : { marginRight: '70%', minWidth: '450px', marginTop: '24px' }}>
            <Card
              restrictWidth
              className={props.className}
            >
              {!done ? (
                <Form
                  url="/api/user/password"
                  method="PUT"
                  buttonText="Save Password"
                  data={{
                    ...(context.user.has_password && {
                      oldpassword: {
                        label: 'Old Password',
                        type: 'password',
                        required: true,
                      },
                      has_password: {
                        type: 'hidden',
                        value: true,
                      },
                    }),
                    newpassword: {
                      label: 'Create New Password',
                      type: 'password',
                      required: true,
                      complexPassword: true,
                    },
                  }}
                  callback={() => {
                    setDone(true)
                    context.update({ has_password: true })
                  }}
                />
              ) : (
                <Message
                  title="Password Saved"
                  type="success"
                  text="Your new password has been saved successfully."
                />
              )}
            </Card>
          </div>
    </MainContentContainer>
  )
}
