export function transformToSingleValueFormat(data) {
  const keys = Object.keys(data[0]);
  const measure = keys.find(property => property !== 'dimension');
  let mainMeasure;

  if (keys.includes('differential')) {
    mainMeasure = keys.filter(item => item !== 'dimension' && item !== 'differential')[0];

    function extractGrowthIndexes(data) {
      const grouped = data.reduce((acc, item) => {
        if (!acc[item.differential]) {
          acc[item.differential] = [];
        }
        acc[item.differential].push(item);
        return acc;
      }, {});

      const result = [];
      for (const [key, value] of Object.entries(grouped)) {
        const lastItem = value[value.length - 1];
        result.push({ name: key, currentValue: lastItem[mainMeasure] });
      }

      return result;
    }

    return extractGrowthIndexes(data)

  } else {
    const result = {
      currentValue: data[data?.length - 1][measure]
    };

    return result;
  }
}