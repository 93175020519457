import { z } from 'zod'

export const ChainForSankeySchema = z.object({
  slug: z.string(),
  name: z.string(),
  logo: z.string()
})

export const PointInfoSankeySchema = z.object({
  slug: z.string(),
  name: z.string(),
  icon: z.string()
})

export const SingleDappDataElement = z.object({
  source: PointInfoSankeySchema,
  target: PointInfoSankeySchema,
  value: z.number(),
  dapp: PointInfoSankeySchema
})

export const DappSankeyDataSchema = z.array(SingleDappDataElement)
