/***
 *
 *   DAPP SANKEY SECTION
 *
 **********/

import React from 'react'
import ReactDOMServer from 'react-dom/server'

import user from 'assets/icons/user-green.svg'
import {
  CustomSankey,
  Typography,
  Icon,
  CustomTooltip,
  Image,
  useMobile
} from 'components/lib'
import informations from 'utils/informations.json'

import { useSankeyData } from 'features/gi/dapp/giDappBasicDashboard/hooks/useSankeyData'
import { convertToSankeyFormat } from 'features/gi/dapp/giDappBasicDashboard/utils/helpers'
import Style from './dappSankeySection.module.scss'

export const DappSankeySection = ({
  dappSlug,
  blockchains
}) => {
  const isMobile = useMobile()
  const { sankeyData, _isLoadingSankeyData } = useSankeyData(dappSlug, blockchains)
  const suffix = ' '
  
  if (!sankeyData) {
    return null
  }

  return (
    <div className={Style.sankeySection}>
      <div className={Style.barContainer}>
        <Image
          source={user}
        />
        <Typography
          tag="h2"
          color="gray900"
          size="m"
          weight='semi-bold'
          text="Movement Of Users"
          className="nowrap"
        />
        <div
          className="relative"
          data-tooltip-id="movement-of-users"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <Typography
              text={informations['Movement of Users']}
              tag="p"
              weight="regular"
              size="s"
              color="gray700"
            />
          )}
          data-tooltip-place="bottom-start"
          data-tooltip-position-strategy="fixed"
          data-tooltip-offset={8}
        >
          <Icon
            name="information2"
            width="16"
            height="16"
          />
          <CustomTooltip
            id="movement-of-users"
            light
          />
        </div>
      </div>
      {sankeyData?.length > 0 ? (
        <CustomSankey
          data={convertToSankeyFormat(sankeyData, suffix, isMobile)}
        />
      ) : null}
      {sankeyData?.length === 0 ? (
        <div className="relative min-height">
          <p className="align-xy">There is currently no data available.</p>
        </div>
      ) : null}
    </div>
  )
}