import React from 'react'
import ReactDOMServer from 'react-dom/server'

import {
  Loader,
  AnimationValue,
  Icon,
  CustomTooltip,
  Typography
} from 'components/lib'

import { TrendLabel } from './TrendLabel'
import Style from './singleValue.module.scss'

export const SingleValue = ({
  data,
  title,
  loading,
  prefix,
  showTrend,
  tooltipInfo
}) => {

  return (
    <div className={Style['single-value']}>
      {!loading &&
      showTrend &&
      data?.change !== null &&
      data?.change !== undefined ? <TrendLabel trend={data.change} /> : (
        <div className={Style['area-cover']}></div>
      )}
      {loading ? <Loader /> : (
        <div className={Style.value}>
          <AnimationValue
            value={data?.currentValue}
            prefix={prefix}
          />
        </div>
      )}
      {title && (
        <div className={Style['name-container']}>
          <p className={Style.name}>{title}</p>
          {tooltipInfo && (
            <div
              className="relative"
              data-tooltip-id={title}
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <Typography
                  text={tooltipInfo}
                  tag="p"
                  weight="regular"
                  size="s"
                  color="gray700"
                />
              )}
              data-tooltip-place="bottom-start"
              data-tooltip-position-strategy="fixed"
              data-tooltip-offset={8}
            >
              <Icon
                name="information"
                width="16"
                height="16"
              />
              <CustomTooltip
                id={title}
                light
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
