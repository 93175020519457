import React from 'react'

import Style from './giBannersContainer.module.scss'

export const GiBannersContainer = ({ children }) => (

  <div className={Style.bannersContainer}>
    {children}
  </div>
)
