/***
 *
 *   ADD DAPP MODAL CONTEXT
 *
 **********/

import React, {
  useState,
  createContext,
  ReactNode,
} from 'react'

export interface IAddDappModalContextProps {
  isDisplayingAddDappModal: boolean
  setIsDisplayingAddDappModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface IAddDappModalProviderProps {
  children: ReactNode
}

export const AddDappModalContext = createContext<IAddDappModalContextProps | undefined>(undefined)

export function AddDappModalProvider({ children }: IAddDappModalProviderProps) {
  const [isDisplayingAddDappModal, setIsDisplayingAddDappModal] = useState(false)

  return (
    <AddDappModalContext.Provider value={{ isDisplayingAddDappModal, setIsDisplayingAddDappModal }}>
      {children}
    </AddDappModalContext.Provider>
  )
}
