/***
 *
 *   LINK
 *   Routes a new view within the application router
 *   Use this instead of <a> to avoid reloading the page
 *
 *   PROPS
 *   url: the destination as defined in /app/app.js
 *   title: link title
 *   text: link text
 *   dark/white: color setting
 *   className: apply a custom css class
 *
 **********/

import React from 'react'
import { NavLink } from 'react-router-dom'
import { ClassHelper } from 'components/lib'
import Style from './link.module.scss'

export function Link(props) {
  const linkStyle = ClassHelper(Style, {
    white: props.color === 'white',
    dark: props.color === 'dark',
    defaultColor: !props.color,
    className: props.className,
    onClick: props.onClick,
    underline: props.underline,
  })

  if (props?.url?.includes('http') || window.location.pathname === props.url) {
    return (
      <a href={props.url} title={props.title} className={linkStyle}>
        {props.children || props.text}
      </a>
    )
  }

  return (
    <NavLink
      to={props.url}
      className={linkStyle}
      title={props.title}
      activeclassname="active"
      onClick={props.onClick}
    >
      {props.children || props.text}
    </NavLink>
  )
}
