/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from 'react'
import { Row, ContactForm, Card } from 'components/lib'

export function Contact() {
  return (
    <Card>
      <Row title="Contact Us">
        <ContactForm />
      </Row>
    </Card>
  )
}
