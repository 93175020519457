import React from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import {
  LineChart,
} from 'echarts/charts'
import {
  GridComponent,
} from 'echarts/components'
import {
  CanvasRenderer,
} from 'echarts/renderers'
import { palette } from 'utils/constans'

echarts.use(
  [
    GridComponent,
    CanvasRenderer,
    LineChart,
  ]
)

export const CustomSimpleAreaChart = ({
  data,
  trend,
  style,
}) => {
  const labels = data.map((item => item.timestamp_date))
  let lineColor = palette.green500
  let gradientColor = palette.green500

  if (trend === 'negative') {
    lineColor = palette.red500
    gradientColor = palette.red300
  }


  const  option = {
    grid: {
      left: 0,
      width: '100%',
    },
    xAxis: {
      type: "category",
      data: labels,
      show: false,
      boundaryGap: false,
    },
    yAxis: {
      type: "value",
      show: false,
      min: 0,
      max: 100,
    },
    series: [
      {
        data: data,
        type: "line",
        symbol: "none",
        smooth: true,
        itemStyle: {
          color: lineColor,
        },
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: palette.white,
            },
            {
              offset: 0,
              color: gradientColor,
            },
          ]),
        },
      },
    ],
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={style}
    />
  )
}
