import { Help } from 'views/help/help'

const Routes = [
  {
    path: '/help',
    view: Help,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Get Help',
  },
]

export default Routes
