/***
 *
 *   GI DAPP BASIC DASHBOARD COMPONENT
 *
 **********/

import React from 'react'

import {
  Loader,
  Breadcrumbs,
  useLocation,
  Typography,
} from 'components/lib'
import {
  useSeoPage
} from 'hooks/useSeoPage'
import { breadcrumbs } from 'utils/constans'

import { BasicValuesSection } from 'features/gi/dapp/giDappBasicDashboard/components/basicValuesSection/BasicValuesSection'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import { GiDappAIInsights } from 'features/gi/dapp/giDappBasicDashboard/components/giDappAIInsights/giDappAIInsights'
import { DappSankeySection } from 'features/gi/dapp/giDappBasicDashboard/components/dappSankeySection/dappSankeySection'
import { TopUsers } from 'features/gi/dapp/giDappBasicDashboard/components/topUsers/topUsers'
import { DappBasicFilters } from 'features/gi/dapp/giDappBasicDashboard/components/dappBasicFilters/DappBasicFilters'
import { DashboardsSection } from 'features/gi/shared/components/dashboardsSection/DashboardsSection'
import { useDapp } from 'features/gi/shared/hooks/useDapp'


export function GiDappBasicDashboard() {
  const location = useLocation()
  const [ , , secondSegment ] = location.pathname.split('/')
  const { selectedDapp } = useDapp(secondSegment)
  const {
    verifiedDappSegment,
    verifiedParamsStr,
    verifiedBlockchainsParam,
    selectedBlockchains,
    selectedDappsToCompare,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
    verifiedBlockchainBreakdownParam,
  } = useVerifiedGiDappsFiltersParams(selectedDapp)
  const breadcrumbsItems = [
    breadcrumbs.home,
    breadcrumbs.dappsOverview,
    {
      key: 'analytics',
      url: `/app/${secondSegment}/analytics${location.search}`,
      name: selectedDapp?.name ? `${selectedDapp?.name} - Analytics` : 'dApp Analytics'
    },
  ]
  useSeoPage({
    title: `${selectedDapp?.name || ' '} Analytics | Patterns`,
    description: `Preview the ${selectedDapp?.name} activity with easy to understand ${selectedDapp?.name} analytics and CRM. Understand ${selectedDapp?.name} potential by monitoring its revenues, user activity and other metrics.`,
    keywords: `${selectedDapp?.name || ' '} analytics`,
    ogTitle: `${selectedDapp?.name || ' '} Analytics | Patterns`,
    ogDescription: `Preview the ${selectedDapp?.name} activity with easy to understand ${selectedDapp?.name} analytics and CRM. Understand ${selectedDapp?.name} potential by monitoring its revenues, user activity and other metrics.`,
    ogImage: selectedDapp?.icon,
    // ogUrl: to set,
  })

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems}  />
      </div>
      {verifiedDappSegment && verifiedParamsStr ? (
        <>
          <BasicValuesSection
            verifiedDappSegment={verifiedDappSegment}
            selectedDapp={selectedDapp}
            selectedDappsToCompare={selectedDappsToCompare}
            verifiedParamsStr={verifiedParamsStr}
            verifiedBlockchainsParam={verifiedBlockchainsParam}
            selectedBlockchains={selectedBlockchains}
          />
          <Typography
            tag="h2"
            text={`${selectedDapp?.name} - User activity`}
            weight="semi-bold"
            size="m"
            color="primary500"
          />
          <DappBasicFilters
            selectedDapp={selectedDapp}
            selectedDappsToCompare={selectedDappsToCompare}
            verifiedDappSegment={verifiedDappSegment}
            verifiedCompareWithParam={verifiedCompareWithParam}
            verifiedDateRangeParam={verifiedDateRangeParam}
            verifiedBlockchainBreakdownParam={verifiedBlockchainBreakdownParam}
            verifiedBlockchainsParam={verifiedBlockchainsParam}
            selectedBlockchains={selectedBlockchains}
          />
          {selectedBlockchains.length > 0 || selectedBlockchains.length === 0 ? (
            <>
              <TopUsers
                dappSlug={selectedDapp?.slug}
                dappName={selectedDapp?.name}
                blockchains={selectedBlockchains}
              />
              <DappSankeySection
                dappSlug={selectedDapp?.slug}
                blockchains={selectedBlockchains}
              />
            </>
          ): null}
          <GiDappAIInsights
            dappName={selectedDapp?.name}
            selectedBlockchains={selectedBlockchains}
            title="Last Week Insights"
          />
          {selectedDapp?.dapp_analytics_dapp && (
            <DashboardsSection
              type="dapp"
              id={selectedDapp?.id}
              slug={selectedDapp?.slug}
            />
          )}
        </>
        ) : (
          <div className="min-height relative"><Loader /></div>
      )}
    </>
  )
}