/***
 *
 *   useBasicValuesData hook
 *   for getting single values of basic components growth index data
 * 
 **********/

import {
  useState,
  useEffect,
} from 'react'
import { toast } from 'react-toastify'

import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'

import { fetchSankeyData } from 'features/gi/dapp/giDappBasicDashboard/utils/fetches'
import { DappSankeyDataSchema } from 'features/gi/dapp/giDappBasicDashboard/types/DappSankeyDataSchema'
import { replaceNoneWithNoOtherActivity } from 'features/gi/dapp/giDappBasicDashboard/utils/helpers'

export const useSankeyData = (dappSlug, blockchains) => {
  const [sankeyData, setSankeyData] = useState()
  const [isLoadingSankeyData, setIsLoadingSankeyData] = useState(false)

  useEffect(() => {
    if (dappSlug) {
      const fetchData = async () => {
        try {
          setIsLoadingSankeyData(true)
          const fetchedSankeyData = await fetchSankeyData(dappSlug, blockchains)
          const validatedSankeyData = DappSankeyDataSchema.safeParse(fetchedSankeyData.data)
          if (!validatedSankeyData.success) {
            logger.error(validatedSankeyData.error)
            throw Error()
          }
          setSankeyData(replaceNoneWithNoOtherActivity(validatedSankeyData?.data))
          setIsLoadingSankeyData(false)
        } catch (err) {
          setIsLoadingSankeyData(false)
          logger.error(err.toString())
          toast.error(getValidationErrorMessage('sankey chart'))
        }
      }

      fetchData()
    } else {
      toast.error(getValidationErrorMessage('Not passed or incorrect dapp slug'))
    }
  }, [dappSlug, blockchains])

  return {
    sankeyData,
    isLoadingSankeyData,
  }
}