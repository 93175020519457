/***
 *
 *   ROUTING HISTORY CONTEXT
 *
 **********/

import React, { useState, createContext } from 'react'

export const RoutingHistoryContext = createContext()

export function RoutingHistoryProvider({ children }) {
  const [routingHistory, setRoutingHistory] = useState([])

  const push = (location) => setRoutingHistory([...routingHistory, location])

  return (
    <RoutingHistoryContext.Provider value={{ routingHistory, push }}>
      {children}
    </RoutingHistoryContext.Provider>
  )
}
