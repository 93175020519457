import React from 'react'
import styles from './avatar.module.scss'
import { getRandomColor } from 'utils/helpers'

export const Avatar = ({ user }) => {
  return (
    <div
      className={styles['avatar-container']}
      style={{
        backgroundColor: getRandomColor(user.email),
      }}
    >
      <span className={styles['avatar-text']}>
        {user.email ? user.email[0].toUpperCase() : ''}
      </span>
    </div>
  )
}
