import { z } from 'zod'

const MetricSchema = z.object({
  metric_name: z.string(),
  metric_value: z.number(),
})

export const DappUsersResponseSchema = z.object({
  data: z.array(
    z.object({
      caller: z.union([z.string(), z.array(z.string())]),
      dapp_name: z.string(),
      date_joined: z.string(),
      deposits: z.number(),
      interactions: z.number(),
      last_interaction_date: z.string(),
      chain: z.any(),
      other_dapps_used: z.array(
        z.object({
          name: z.string(),
          slug: z.string(),
          logo: z.string().optional(),
        })
      ).optional(),
    })
  ),
  metrics: z.array(MetricSchema),
  totalRecords: z.number(),
})