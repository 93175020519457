import React from 'react'
import { NavLink } from 'react-router-dom'

import { ButtonNew, Icon, Typography } from 'components/lib'
import { paths } from 'utils/paths'

import Style from './SlideAddDashboard.module.css'

export const SlideAddDashboard = () => {
  return (
    <NavLink to={paths.dashboardBuilder} className={Style['slide-button']}>
      <Typography
        tag="p"
        text="There are currently no dashboards, you can create your own in the dashboard builder"
        color="gray700"
        size="s"
        align="center"
      />
      <ButtonNew
        moveTo={paths.dashboardBuilder}
        size="large"
        startIcon={<Icon name="plus" width="16" height="16" color="white" />}
      >
        Create Dashboard
      </ButtonNew>
    </NavLink>
  )
}
