/***
 *
 *
 *  VERIFY ACCOUNT MODAL CONTENT COMPONENT
 *
 *
 **********/

import React, { useContext, useRef } from 'react'
import { Button, AuthContext, IconLib, Modal } from 'components/lib'
import { useResendVerificationEmail } from './hooks/useResendVerificationEmail'
import Style from './verifyAccount.module.scss'

export const RequireVerification = () => {
  const authContext = useRef(useContext(AuthContext))
  const resendVerificationEmail = useResendVerificationEmail()

  return (
    <Modal title="This feature requires verification" redirect>
      <div className={Style.container}>
        <div className={Style.frame}>
          <IconLib image="cursor" size={40} pack="own" />
          <p>A verification link was sent to</p>
          <p>{authContext.current.user.email}</p>
        </div>
        <p className={Style.informationText}>
          Oops! Looks like you still haven’t verified your account, please check
          you inbox for the verification link
        </p>
        <Button
          text="Didn’t receive email? Resend"
          fullWidth
          outline
          outlineDark
          action={() => resendVerificationEmail()}
        />
      </div>
    </Modal>
  )
}
