import React, { useState } from 'react'
import Cookies from 'js-cookie'

import {
  ButtonNew,
  Typography,
  Icon,
  IconButton,
} from 'components/lib'

import Style from './AddDappTopBar.module.css'

export const AddDappTopBar = ({
  setIsDisplayingAddDappModal,
  setPreviewDapp
}) => {
  const displayedAddDappTooltipInfo = Cookies.get('displayedAddDappTooltipInfo')
  const [_isTooltipOpen, setIsTooltipOpen] = useState(true)

  return (
    <div className={Style['add-dapp-top-bar']}>
      <Typography
        tag="h1"
        text="Dashboard Builder"
        size="m"
        weight="medium"
        color="gray900"
      />
      <ButtonNew
        onClick={() => {
          setPreviewDapp()
          setIsDisplayingAddDappModal(true)
        }}
        startIcon={<Icon name="plus" width="16" height="16" color="white" />}
      >
        Add dApp
      </ButtonNew>
      {!displayedAddDappTooltipInfo && <SpecialTooltip setIsTooltipOpen={setIsTooltipOpen} />}
    </div>
  )
}


export const SpecialTooltip = ({
  setIsTooltipOpen
}) => {
  const handleDisplayingAddDappTooltip = () => {
    Cookies.set('displayedAddDappTooltipInfo', JSON.stringify(true), { expires: 365 })
    setIsTooltipOpen(false)
  }

  return (
    <div className={Style['special-tooltip']}>
      <IconButton
        onClick={() => handleDisplayingAddDappTooltip()}
        icon={
          <Icon
            name="exit"
            width="16"
            height="16"
            color="gray400"
          />
        }
      />
      <Typography
        tag="p"
        text="Add your dApp here!"
        size="m"
        weight="regular"
        color="primary500"
      />
      <Icon
        name="play"
        width="16"
        height="16"
      />
    </div>
  )
}