/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan - not available at the moment
 *
 **********/

import React from 'react'
import { Link } from 'react-router-dom'

import { Modal, Disclaimer } from 'components/lib'
import { BRAND_NAME } from 'utils/constans'

import { SignUpContainer } from 'features/auth/signUp'
import { ContentAuthGrid } from 'features/auth/components/contentAuthGrid'
import { InformationAuthColumn } from 'features/auth/components/informationAuthColumn'

export const Signup = () => (
  <Modal title="Register to platform" redirect>
    <ContentAuthGrid>
      <div>
        <SignUpContainer />
        <Disclaimer>
          Existing {BRAND_NAME} user? <Link to="/signin">Log in</Link>
        </Disclaimer>
      </div>
      <InformationAuthColumn
        headline="Gain access to amazing features"
        text={`Create your ${BRAND_NAME} account and access features like creating
        custom dashboards and advanced filters`}
      />
    </ContentAuthGrid>
  </Modal>
)
