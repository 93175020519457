import { palette } from 'utils/constans'

export const tokenguardTheme = {
  name: "Tokenguard",
  primaryColor: palette.green500,
  secondaryColor: "#0A425E",
  additionalColor: "#E6A627",
  bgColor: palette.white,
  itemGridRadius: "0.6rem",
  itemGridBgColor: palette.white,
  font: "Roboto",
  textColor: palette.gray900,
  itemGridStroke: palette.gray100,
  chartGradient: true,
  bottomTimeline: true,
}