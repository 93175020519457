import { paths } from 'utils/paths'

export const palette = {
  // TO DO => REMOVE dark600, dark500
  dark600: '#093c56',
  dark500: '#0a425e',

  primary900: '#161616',
  primary800: '#1d1d1d',
  primary700: '#262626',
  primary600: '#303030',
  primary500: '#353535',
  primary: '#353535',
  primary400: '#5d5d5d',
  primary300: '#787878',
  primary200: '#a2a2a2',
  primary100: '#c0c0c0',
  primary50: '#ebebeb',

  green900: '#0e4725',
  green800: '#135d30',
  green700: '#18783e',
  green600: '#1f9a4f',
  green500: '#22a957',
  green400: '#4eba79',
  green300: '#6bc58e',
  green200: '#99d7b2',
  green100: '#bae4cb',
  green50: '#e9f6ee',
  green25: '#f4fbf6',

  red500: '#e61c50',
  red: '#e61c50',
  red300: '#ee678a',
  red50: '#fde8ee',

  yellow500: '#fcc53e',
  yellow50: '#fff9ec',

  gray900: '#101828',
  gray800: '#1D2939',
  gray700: '#344054',
  gray600: '#475467',
  gray500: '#667085',
  gray400: '#98a2b3',
  gray300: '#d0d5dd',
  gray200: '#eaecf0',
  gray100: '#f2f4f7',
  gray75: '#ececec',
  gray50: '#f9fafb',
  gray25: '#fcfcfd',
  white: '#ffffff',
}

export const breakpoints = {
  mobile: 1024,
}

export const plan = {
  alephZero: 'Aleph Zero',
  astar: 'Astar'
}

export const BRAND_NAME = 'Patterns'
export const INDICATOR_NAME = 'Activity'

export const breadcrumbs = {
  home: {
    key: 'home',
    url: '/',
    name: 'Home',
  },
  dappsOverview: {
    key: 'dApps',
    url: paths.giDappOverview,
    name: 'dApps',
  },
  ecosystemOverview: {
    key: 'overview',
    url: paths.giEcosystemOverview,
    name: 'Crypto Activity',
  },
}