import { z } from 'zod'
import { SingleBlockchainSchema } from './blockchain'

export const SmartContractDataSchema = z.object({
  name: z.string(),
  address: z.string(),
  abi: z.unknown(),
  fileName: z.string().optional(),
  fileSize: z.number().optional(),
  i: z.string().optional()
})

export const DappToAddSchema = z.object({
  name: z.string(),
  logo: z.string(),
  blockchain: z.string(),
  from_block: z.union([z.string(), z.number()]).optional(),
  addedBy: z.string().optional(),
  added_by: z.union([z.string(), z.null()]).optional(),
  id: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  abis: z.array(SmartContractDataSchema),
  website: z.string().optional(),
})

export const SingleDappSchema = z.object({
  name: z.string(),
  logo: z.string(),
  blockchain: z.union([SingleBlockchainSchema, z.null()]),
  from_block: z.union([z.string(), z.number()]).optional(),
  addedBy: z.string().optional(),
  added_by: z.union([z.string(), z.null()]).optional(),
  id: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  indexingStatus: z.union([z.string(), z.number()]).optional(),
  containerStatus: z.string().optional(),
  website: z.union([z.string(), z.null()]).optional(),
})

export const DappsSchema= z.array(SingleDappSchema)

export const SingleDappDetailsSchema = z.object({
  name: z.string(),
  logo: z.string(),
  blockchain: z.string(),
  from_block: z.number().optional(),
  added_by: z.union([z.string(), z.null()]).optional(),
  addedBy: z.string().optional(),
  abis: z.array(SmartContractDataSchema).optional(),
  id: z.string().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  indexingStatus: z.union([z.string(), z.number()]).optional(),
  containerStatus: z.string().optional(),
  website: z.union([z.string(), z.null()]).optional(),
})

export const DappsDetailsSchema= z.array(SingleDappDetailsSchema)

export type TDapps = z.infer<typeof DappsSchema>
export type TDapp = z.infer<typeof SingleDappSchema>
export type TDappToAdd = z.infer<typeof DappToAddSchema>
export type TDappsDetails = z.infer<typeof DappsDetailsSchema>
export type TSingleDappDetails= z.infer<typeof SingleDappDetailsSchema>
export type TSmartContractData = z.infer<typeof SmartContractDataSchema>
