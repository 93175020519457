/***
 *
 *
 *  SignIn container
 *
 *
 *
 **********/

import React, { useContext, useState, useEffect } from 'react'
import {
  AuthContext,
  ViewContext,
  Button,
  Form,
  Message,
  SocialSignin,
  Line,
  useLocation,
  useNavigate,
} from 'components/lib'

export const SignInContainer = () => {
  const authContext = useContext(AuthContext)
  const viewContext = useContext(ViewContext)
  const location = useLocation()
  const navigate = useNavigate()
  const privatePathToRedirect = JSON.parse(
    localStorage.getItem('privatePathToRedirect')
  )

  // state
  const [useMagic, setUseMagic] = useState(false)
  const [magicLinkSent, setMagicLinkSent] = useState(false)
  const [form, setForm] = useState({
    email: {
      label: 'Email Address',
      placeholder: 'Enter your email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?',
    },
  })

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search

    if (qs.includes('error')) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=') + 7))
      viewContext.notification.show(msg, 'error')
    }
  }, [location.search, viewContext.notification])

  function toggleMagicLink(useMagic) {
    const f = { ...form }
    f.password.type = useMagic ? 'hidden' : 'password'
    f.password.required = useMagic ? false : true
    f.forgotpassword.type = useMagic ? 'hidden' : 'link'

    setForm(f)
    setUseMagic(useMagic)
  }

  if (magicLinkSent) {
    return (
      <div style={{ width: '100%', margin: '0 auto' }}>
        <Message
          title="Check Your Email"
          type="success"
          text="Please click the link in your email inbox to sign in."
        />
      </div>
    )
  }

  return (
    <>
      <SocialSignin network={['google']} useMagic={useMagic} />

      {useMagic ? (
        <Button
          text="Use password"
          iconColor="primary"
          icon="faShield"
          iconButton
          iconPack="fontawesome"
          iconSize="1x"
          alignLeft
          outlinePrimary
          className="mx-auto mb-5"
          fullWidth
          action={() => {
            toggleMagicLink(false)
          }}
        />
      ) : (
        <Button
          text="Use magic link"
          className="mx-auto mb-5"
          iconColor="primary"
          icon="faStar"
          iconButton
          iconPack="fontawesome"
          iconSize="1x"
          alignLeft
          outlinePrimary
          fullWidth
          action={() => {
            toggleMagicLink(true)
          }}
          showOr
        />
      )}

      <Line showOr className="mb16 mt16" />

      <Form
        data={form}
        method="POST"
        url={useMagic ? '/api/auth/magic' : '/api/auth'}
        buttonText={useMagic ? 'Send Magic Link' : 'Log In'}
        callback={(res) => {
          useMagic
            ? setMagicLinkSent(true)
            : res.data['2fa_required']
            ? navigate(`/signin/otp?token=${res.data.token}`)
            : navigate(authContext.signin(res, privatePathToRedirect))
        }}
      />
    </>
  )
}
