import { z } from 'zod'

import { LayoutSchema } from './layout'
import { DashboardInfoSchema } from './info'
import { ElementSchema } from './element'
// import { ThemeSchema } from './theme'
import { FilterSchema } from './filter'

export const DashboardCreatorSchema = z.object({
  _id: z.string(),
  title: z.string(),
  elements: z.array(ElementSchema),
  layout: LayoutSchema,
  filters: z.array(FilterSchema),
  __v: z.number().optional()
})

export const DashboardSchema = z.object({
  dashboard: DashboardInfoSchema,
  dashboardCreator: DashboardCreatorSchema,
})

export type TDashboard = z.infer<typeof DashboardSchema>