/***
 *
 *   BASIC VALUES SECTION
 *
 **********/

import React from 'react'
import {
  ButtonNew,
  Card,
  useLocation,
  Typography,
  Icon
} from 'components/lib'
import { INDICATOR_NAME } from 'utils/constans'

import { COLLECTION_NAME } from 'features/gi/shared/utils/constans'
import cumulativeIcon from 'features/gi/shared/assets/icons/cumulative.svg'
import { SingleValueGrid } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/singleValueGrid/singleValueGrid'
import { BasicValuesContainer } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/basicValuesContainer/BasicValuesContainer'
import { GaugeSection } from 'features/gi/ecosystem/shared/components/gaugeSection/gaugeSection'
import { useBasicValuesData } from 'features/gi/ecosystem/giEcosystemBasicDashboard/hooks/useBasicValuesData'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import Style from './BasicValuesSection.module.css'

export function BasicValuesSection() {
  const location = useLocation()
  const { basicValuesData, isBasicValuesDataLoading } = useBasicValuesData()
  const {
    verifiedBlockchainSegment,
    selectedBlockchain,
    selectedBlockchainsToCompare
  } = useVerifiedFiltersParams()

  const handleDisplayingComparison = () => {
    if (!selectedBlockchainsToCompare) {
      return null
    }

    if (selectedBlockchainsToCompare.length > 1) {
      return (
        {
          name: COLLECTION_NAME,
          logo: cumulativeIcon
        }
      )
    }

    if (selectedBlockchainsToCompare.length === 1) {
      return (
        {
          name: selectedBlockchainsToCompare[0].blockchain.name,
          logo: selectedBlockchainsToCompare[0].blockchain.logo,
        }
      )
    }
  }

  return (
    <Card className="mt24">
      <div className={Style['title-container']}>
        <Typography
          text={`${selectedBlockchain?.blockchain?.name} Last Week Stats`}
          tag="h1"
          size="m"
          weight="semi-bold"
          color="primary500"
        />
        <ButtonNew
          size="medium"
          color="primary"
          moveTo={`/crypto/${verifiedBlockchainSegment}/charts${location.search}`}
          endIcon={<Icon name="rightChevron" color="white" width="12" height="16" />}
        >
          Explore {selectedBlockchain?.blockchain?.name} {INDICATOR_NAME}
        </ButtonNew>
      </div>
      <BasicValuesContainer>
        <GaugeSection
          title={`Ecosystem ${INDICATOR_NAME}`}
          data={basicValuesData}
          loadingData={isBasicValuesDataLoading}
          blockchain={{
            name: selectedBlockchain?.blockchain?.name,
            logo: selectedBlockchain?.blockchain?.logo
          }}
          comparison={handleDisplayingComparison()}
        />
        <SingleValueGrid
          data={basicValuesData}
          loadingData={isBasicValuesDataLoading}
        />
      </BasicValuesContainer>
    </Card>
  )
}
