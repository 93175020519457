/***
 *
 *   OVERVIEW
 *   Ranking of dapps
 *
 **********/

import React from 'react'
import { Helmet } from 'react-helmet'

import {
  Animate,
  Breadcrumbs,
  MainContentContainer,
} from 'components/lib'
import { breadcrumbs } from 'utils/constans'
import {
  useSeoPage
} from 'hooks/useSeoPage'
import ogImage from 'assets/images/og/ecosystem-ranking.png'

import { GiDappOverview } from 'features/gi/dapp/giDappOverview/giDappOverview'


export function GiDappOverviewPage() {
  useSeoPage({
    title: 'Top dApps | Patterns',
    description: 'Discover top crypto apps, gain in-depth insights about their activity, and utilize advanced analytics to better understand user behavior & revenue.',
    keywords: ['dapps', 'ranking', 'top', 'crypto apps'],
    ogTitle: 'Top dApps | Patterns',
    ogDescription: 'Discover top crypto apps, gain in-depth insights about their activity, and utilize advanced analytics to better understand user behavior & revenue.',
    ogImage: ogImage,
    // ogUrl: to set
  })
  const breadcrumbsItems = [
    breadcrumbs.home,
    breadcrumbs.dappsOverview,
  ]

  return (
    <>
      <Helmet>
        <link rel="canonical" href="http://patterns.build" />
      </Helmet>
      <MainContentContainer>
        <Animate type="pop">
          <div className="mb24">
            <Breadcrumbs items={breadcrumbsItems} />
          </div>
          <GiDappOverview />
        </Animate>
      </MainContentContainer>
    </>
  )
}
