import React, {
  useEffect,
  useState,
} from 'react'

import {
  SingleValue,
} from 'components/lib'
import informations from 'utils/informations.json'

import {
  USER_ACQUISTION,
  USER_RETENTION,
  USERS_THAT_CHURNED,
  TVL,
  MONETIZATION_FEES,
  INTERACTIONS,
} from 'features/gi/dapp/shared/utils/constans'
import Style from './singleValueGrid.module.scss'

export const SingleValueGrid = ({ data, loadingData }) => {
  const [singleVaulesData, setSingleValuesData] = useState([
    { name: USER_ACQUISTION, id: 'new_users' },
    { name: USER_RETENTION, id: 'returning_users' },
    { name: USERS_THAT_CHURNED, id: 'churned_users' },
    { name: MONETIZATION_FEES, prefix: '$', id: 'fees_usd' },
    { name: TVL, prefix: '$', id: 'tvl' },
    { name: INTERACTIONS, id: 'calls' },
  ])

  useEffect(() => {
    if (data) {
      const dataProperties = Object.keys(data)
      const blockchainSlug = dataProperties.find(item => item !== 'mainDappName')
      const blockchainProperties = Object.keys(data[blockchainSlug])
      blockchainProperties.forEach(item => {
      setSingleValuesData(prevState => prevState.map(obj => {  
        if (obj.id === item) {
          return {
            ...obj,
            data: data[blockchainSlug][item]
          }
        }
        return obj
      }))
     })
    }
  }, [data])

  return (
    <div className={Style.grid}>
      {singleVaulesData.map((item) => {

        return (
          <SingleValue
            showTrend
            darkMode
            key={item.name}
            title={item.name}
            prefix={item.prefix}
            data={item.data}
            loading={loadingData}
            tooltipInfo={informations.dapp[item.name]}
          />
        )
      })}
    </div> 
  )
}
