/***
 *
 *   TG GROWTH INDEX COMPARISION SECTION
 *
 **********/

import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'

import {
  Card,
  ViewContext,
  Loader,
  AuthContext,
  Typography
} from 'components/lib'
import { endpoint } from 'utils/paths'

import { ComparisionBlockchainsTable } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/comparisionBlockchainsTable/comparisionBlockchainsTable'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'

export function ComparisionSection() {
  const { verifiedBlockchainSegment } = useVerifiedFiltersParams()
  const [comparisionBlockchainsData, setComparisionBlockchainsData] = useState([])
  const [isComparisionBlockchainsDataLoading, setIsComparisionBlockchainsDataLoading] = useState(false)
  const viewContext = useContext(ViewContext)
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (verifiedBlockchainSegment) {
      const fetchComparisionBlockchainsData = async () => {
        try {
          setIsComparisionBlockchainsDataLoading(true)
        
          const comparisionBlockchainsDataResponse = await axios.get(`${endpoint.apiGiComparison}`)
          setComparisionBlockchainsData(comparisionBlockchainsDataResponse.data.data)
          setIsComparisionBlockchainsDataLoading(false)
            
        } catch (err) {
          setIsComparisionBlockchainsDataLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
      if (authContext.user.plan === 'Aleph Zero') {
        fetchComparisionBlockchainsData()
      }
    }

  }, [verifiedBlockchainSegment])

  return (
    <Card>
      <div className="align-center">
        <Typography
          text="TG Growth Index Comparison"
          tag="span"
          color="primary500"
          size="xs"
          weight="regular"
        />
      </div>
      {!isComparisionBlockchainsDataLoading && comparisionBlockchainsData.length > 0 && <ComparisionBlockchainsTable data={comparisionBlockchainsData} />}
      {!isComparisionBlockchainsDataLoading && comparisionBlockchainsData.length === 0 && <div className="min-height relative"><p className="align-xy">No data</p></div>}
      {isComparisionBlockchainsDataLoading && <div className="min-height relative"><Loader /></div>}
    </Card>
  )
}
