/***
 *
 *   VERIFY ACCOUNT NOTIFICATION
 *   container with information about no vierified account + button to resend
 *
 *
 **********/

import React from 'react'
import Style from './verifyAccountNotification.module.scss'

import { useResendVerificationEmail } from '../hooks/useResendVerificationEmail'
import { Message, Button, IconLib } from 'components/lib'

export const VerifyAccountNotification = () => {
  const resendVerificationEmail = useResendVerificationEmail()

  return (
    <Message type="error" className={Style.test}>
      <div className={Style.msgContainer}>
        <IconLib image="union" pack="own" />
        <p>Verify your email address to unlock all features</p>
        <Button
          small
          color="white"
          text="Resend email"
          action={() => resendVerificationEmail()}
        />
      </div>
    </Message>
  )
}
