/***
 *
 *   MY DASHBOARDS
 *   Page with grid of all user dashboards
 *
 **********/

import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AuthContext,
  ViewContext,
  Animate,
  useDashboards,
  Grid,
  Loader,
  Container,
  Slide
} from 'components/lib'
import { paths } from 'utils/paths'
import { mixpanelTrack } from 'utils/mixpanel'

import { SlideButton } from 'features/dashboardsGrid/shared/components/SlideButton'

export const MyDashboardsGrid = () => {
  const authContext = useContext(AuthContext)
  const viewContext = useContext(ViewContext)
  const userId = authContext.user.accounts[0].user_id
  const [userDashboards, setUserDashboards] = useState()
  const { dashboards, isLoadingDashboards } = useDashboards(`/user?created_by=${userId}&state=saved`)
  const navigate = useNavigate()

  const options = [
    {
      label: 'Edit',
      action: (id) => navigate(`${paths.dashboardBuilder}/edit/${id}`),
    },
    {
      label: 'Share',
      action: (id) => {
        viewContext.modal.show({
          title: 'Share dashboard',
          shareContent: { contentId: id, contentType: 'dashboard' },
        })
      },
    },
    {
      label: 'Delete',
      action: (id) => {
        viewContext.modal.show(
          {
            title: 'Delete dashboard',
            text: 'Are you sure you want to delete this dashboard?',
            form: {},
            destructive: true,
            buttonText: 'Delete',
            url: `/api/dashboard/${id}`,
            method: 'DELETE',
          },
          () => {
            toast.success('Dashboard was deleted')
            const updatedUserDashboards = userDashboards.filter(
              (dashboard) => dashboard.id !== id
            )
            setUserDashboards(updatedUserDashboards)
            mixpanelTrack('Delete dashboard', authContext.user.user_id)
          }
        )
      },
      type: 'danger',
    },
  ]

  useEffect(() => {
    if (dashboards.length >= 0) {
      setUserDashboards(dashboards)
    }
  }, [dashboards])

  return (
    <Container noPadding>
      <Animate type="pop">
        <div className="relative min-height mt24">
          <Grid displayGrid>
            <SlideButton />
            {userDashboards &&
              userDashboards.length > 0 &&
              userDashboards.map(
                ({ title, id, dappName, dappLogo }) => {
                  return (
                    <Slide
                      path={`${paths.dashboard}/${id}`}
                      id={id}
                      title={title}
                      key={id}
                      options={options}
                      dappName={dappName}
                      dappLogo={dappLogo}
                    />
                  )
                }
              )}
          </Grid>
          {isLoadingDashboards && <Loader />}
        </div>
      </Animate>
    </Container>
  )
}
