import React, { useState, useContext } from 'react'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { TextInputNew, IconButton, ButtonNew } from 'components/lib'
import { Icon } from 'components/icon/Icon'
import { removeUselessProperties } from 'utils/helpers'

import { BlockChartContext } from 'features/builders/shared/contexts/BlockChartContext'
import Style from './SettingsChart.module.css'

export const SettingsChart = ({
  setChartType,
  chartType,
  chartTitle,
  setChartTitle,
  dappId,
  selectedUnit,
  isScBreakdown,
  result,
  selectedCalls,
  selectedEvents,
}) => {
  const [editing, setEditing] = useState<boolean>(false)
  const blockChartContext = useContext(BlockChartContext)
  const dashboardContentContext = useContext(DashboardContentContext)

  if (!blockChartContext) {
    throw Error('Chart context has to be in provider')
  }

  if (!dashboardContentContext) {
    throw Error('Dashboard context has to be in provider')
  }

  const { blockChartId, setBlockChartId } = blockChartContext
  const {
    dashboardElements,
    setDashboardElements,
    dashboardLayout,
    setDashboardLayout,
  } = dashboardContentContext

  const saveElement = (blockChartId) => {
    if (dashboardElements.some((obj) => obj?.id === blockChartId)) {
      const updatedDashboardElements = dashboardElements.map((element) => {
        if (element.id === blockChartId) {
          const selectedCallsWithoutFilterOptions =
            removeUselessProperties(selectedCalls)
          const selectedEventsWithoutFilterOptions =
            removeUselessProperties(selectedEvents)
          const elementToSave = {
            id: element.id,
            title: chartTitle,
            visType: chartType,
            metric: selectedUnit.value,
            breakdown: isScBreakdown,
            data: result,
            filters: [],
          }
          selectedCallsWithoutFilterOptions &&
            elementToSave.filters.push(...selectedCallsWithoutFilterOptions)
          selectedEventsWithoutFilterOptions &&
            elementToSave.filters.push(...selectedEventsWithoutFilterOptions)

          return {
            ...element,
            ...elementToSave,
          }
        } else {
          return element
        }
      })
      setDashboardElements(updatedDashboardElements)
      setBlockChartId(null)
    } else {
      const selectedCallsWithoutFilterOptions =
        removeUselessProperties(selectedCalls)
      const selectedEventsWithoutFilterOptions =
        removeUselessProperties(selectedEvents)
      const elementToSave = {
        type: 'dappAnalytics',
        visType: chartType,
        id: blockChartId,
        i: blockChartId,
        title: chartTitle,
        dappId: dappId,
        metric: selectedUnit.value,
        breakdown: isScBreakdown,
        data: result,
        filters: [],
      }
      selectedCallsWithoutFilterOptions &&
        elementToSave.filters.push(...selectedCallsWithoutFilterOptions)
      selectedEventsWithoutFilterOptions &&
        elementToSave.filters.push(...selectedEventsWithoutFilterOptions)
      setDashboardElements([...dashboardElements, elementToSave])
      setDashboardLayout([
        ...dashboardLayout,
        {
          id: blockChartId,
          i: blockChartId,
          static: false,
          x: 0,
          y: 0,
          h: 24,
          w: 4,
        },
      ])
      setBlockChartId(null)
    }
  }

  const ref = useOutsideClick(() => {
    setEditing(false)
  })

  const handleClick = () => {
    setEditing(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChartTitle(e.target.value)
  }

  const handleBlur = () => {
    setEditing(false)
  }

  return (
    <div className={Style['settings-chart-container']}>
      <div className={Style['chart-title-container']} ref={ref}>
        {editing ? (
          <TextInputNew
            name="chart-title"
            value={chartTitle}
            change={handleChange}
            blur={handleBlur}
          />
        ) : (
          <p className={Style['chart-title']}>{chartTitle}</p>
        )}
        <IconButton
          onClick={handleClick}
          icon={<Icon name="edit" height="1.6rem" width="1.6rem" />}
        />
      </div>
      <div className={Style['charts-icons-container']}>
        <IconButton
          onClick={() => setChartType('lineChart')}
          icon={
            <Icon
              name={chartType === 'lineChart' ? 'lineChartActive' : 'lineChart'}
              width="24"
              height="24"
            />
          }
        />
        <IconButton
          onClick={() => setChartType('singleValue')}
          icon={
            <Icon
              name={
                chartType === 'singleValue'
                  ? 'singleValueActive'
                  : 'singleValue'
              }
              width="24"
              height="24"
            />
          }
        />
        <IconButton
          onClick={() => setChartType('barChart')}
          icon={
            <Icon
              name={chartType === 'barChart' ? 'barChartActive' : 'barChart'}
              width="24"
              height="24"
            />
          }
        />
        <IconButton
          onClick={() => setChartType('pieChart')}
          icon={
            <Icon
              name={chartType === 'pieChart' ? 'pieChartActive' : 'pieChart'}
              width="24"
              height="24"
            />
          }
        />
      </div>
      <ButtonNew onClick={() => saveElement(blockChartId)}>
        Save to Dashboard
      </ButtonNew>
    </div>
  )
}
