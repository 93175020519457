/***
 *
 *   DASHBOARD LAYOUT
 *
 *   PROPS
 *   children
 *
 **********/

import React from 'react'

import Style from './DashboardLayout.module.css'

export function DashboardLayout(props) {

  return (
    <main className={Style['dashboard-layout']}>{<props.children {...props.data} />}</main>
  )
}
