/***
 *
 *   useDashboard hook
 *   fetch, format and return dashboard
 *
 **********/

import {
  useState,
  useEffect,
  useContext,
} from 'react'

import { DashboardSchema } from 'types/dashboard/dashboard'
import { fetchDashboard } from 'utils/fetches/dashboard'
import { logger } from 'utils/logger'
import { ViewContext } from 'components/lib'
import { getValidationErrorMessage } from 'utils/helpers'

export const useDashboard = (id) => {
  const [dashboard, setDashboard] = useState()
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false)
  const viewContext = useContext(ViewContext)

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          setIsLoadingDashboard(true)
          const fetchedDashboardData = await fetchDashboard(id)
          const validatedDashboard = DashboardSchema.safeParse(fetchedDashboardData)
          if (!validatedDashboard.success) {
            logger.error(validatedDashboard.error)
            throw Error(getValidationErrorMessage('Dashboard'))
          }
          setDashboard(validatedDashboard.data)
          setDashboard(fetchedDashboardData)
          setIsLoadingDashboard(false)
        } catch (err) {
          setIsLoadingDashboard(false)
          viewContext && viewContext.handleError(err)
        }
      }
        
      fetchData()
    }
  }, [id])

  return {
    dashboard,
    isLoadingDashboard,
    setDashboard,
    setIsLoadingDashboard
  }
}
