import mixpanel from 'mixpanel-browser'

let mixPanelInitialized = false
const eventQueue = []
const useMixpanel = true

export function mixpanelInitialize(token, options) {
  if (mixPanelInitialized || !useMixpanel) {
    return
  }

  try {
    mixpanel.init(token, options)
    mixPanelInitialized = true

    while (eventQueue.length > 0) {
      const { event, data } = eventQueue.shift()
      if (event === 'Page View') {
        mixpanelTrackPageInternal(data.page, data.userId)
      } else {
        mixpanelTrackInternal(event, data)
      }
    }
  } catch (error) {
    console.error('Mixpanel initialization failed:', error)
  }
}

function mixpanelTrackInternal(event, data) {
  const { user_id, ...otherData } = data
  if (event === 'Sign up' && data && data.user_id && data.name && data.email) {
    setUserProfile(data.user_id, data.name, data.email)
  }
  mixpanel.track(event, { distinct_id: user_id, ...otherData })
}

export function mixpanelTrack(event, data) {
  if (!mixPanelInitialized || !useMixpanel) {
    eventQueue.push({ event, data })
    return
  }

  mixpanelTrackInternal(event, data)
}

function mixpanelTrackPageInternal(page, userId = null) {
  try {
    const properties = userId ? { distinct_id: userId, page } : { page }
    mixpanel.track('Page View', properties)
  } catch (error) {
    console.error(`Error tracking page view for page: ${page}`, error)
  }
}

export function mixpanelTrackPage(page, userId = null) {
  if (!mixPanelInitialized || !useMixpanel) {
    eventQueue.push({ event: 'Page View', data: { userId, page } })
    return
  }

  mixpanelTrackPageInternal(page, userId)
}

const setUserProfile = (userId, userName, userEmail) => {
  if (!mixPanelInitialized || !useMixpanel) {
    return
  }
  try {
    mixpanel.people.set(userId, {
      $email: userEmail,
      $name: userName,
    })
  } catch (error) {
    console.error('Error setting Mixpanel user profile:', error)
  }
}
