import { TBlockchainOption } from 'types/blockchain'
import { TDapp } from 'types/dapp'

import placeholder from 'features/gi/shared/assets/icons/placeholder.svg'

import { TOverflowElementType } from 'features/gi/dapp/shared/types/overflowElements'

export const handleImageSource = (
  element: TDapp | TBlockchainOption,
  type: TOverflowElementType
) => {
  if (!element) {
    return placeholder
  }

  if (type === 'dapp') {
    const dappElement = element as TDapp
    if (!dappElement.logo || dappElement.logo === 'not available') {
      return placeholder
    } else {
      return dappElement.logo
    }
  } else {
    const blockchainElement = element as TBlockchainOption
    return blockchainElement.icon || blockchainElement.logo
  }
}