/***
 *
 *   REDESIGN APP LAYOUT
 *   Main application layout, visible for logged user
 *
 *   PROPS
 *   children
 *
 **********/

import React, { Fragment } from 'react'

import {
  Page
} from 'components/lib'
import stainTop from 'assets/images/background-stain-top.svg'
import stainBottom from 'assets/images/background-stain-bottom.svg'

import Style from './redesignApp.module.scss'

export function RedesignAppLayout(props) {

  return (
    <Fragment>
      <Page>
        <main className={Style.app}>{<props.children {...props.data} />}</main>
        <img className={Style.bgStainTop} src={stainTop} alt="" />
        <img className={Style.bgStainBottom} src={stainBottom} alt="" />
      </Page>
    </Fragment>
  )
}
