/***
 *
 *   useBlockchains hook
 *   fetch, format and return blockchains
 *
 **********/

import {
  useState,
  useEffect,
} from 'react'
import { toast } from 'react-toastify'

import { BlockchainsSchema } from 'types/blockchain'
import { fetchBlockchains } from 'utils/fetches/blockchain'
import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'

export const useBlockchains = (param: string) => {
  const [blockchains, setBlockchains] = useState()
  const [isLoadingBlockchains, setIsLoadingBlockchains] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingBlockchains(true)
        const fetchedBlockchainsData = await fetchBlockchains(param)
        const validatedBlockchains = BlockchainsSchema.safeParse(fetchedBlockchainsData.data)
        if (!validatedBlockchains.success) {
          logger.error(validatedBlockchains.error)
          throw Error(getValidationErrorMessage('Blockchains'))
        }
        setBlockchains(validatedBlockchains.data)
        setIsLoadingBlockchains(false)
      } catch (err) {
        setIsLoadingBlockchains(false)
        toast.error(err.toString())
      }
    }
      
    fetchData()
  }, [])

  return {
    blockchains,
    isLoadingBlockchains,
  }
}
