import { useContext } from 'react'
import Axios from 'axios'
import { ViewContext, useNavigate } from 'components/lib'

export const useResendVerificationEmail = () => {
  const viewContext = useContext(ViewContext)
  const navigate = useNavigate()

  return async function resendVerificationEmail() {
    try {
      await Axios({ method: 'post', url: '/api/user/verify/request' })

      viewContext.modal.hide(true)
      navigate('/')
      viewContext.notification.show(
        `Please click the link in the email we sent to verify your account.`,
        'success',
        true
      )
    } catch (err) {
      viewContext.modal.hide(true)
      navigate('/')
      viewContext && viewContext.handleError(err)
    }
  }
}
