import axios from 'axios'
import { endpoint } from 'utils/paths'
import settings from 'settings'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchBlockchains = async (param) => {
  const response = await axios.get(`${serverURL}/${endpoint.apiBlockchains}${param}`)

  return response.data
}