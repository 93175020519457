import React from 'react'
import Style from './breakdownContainer.module.scss'


export const BreakdownContainer = ({ children }) => {
  return (
    <section className={Style.container}>
      {children}
    </section>
  )
}
