/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import { gtm } from 'utils/analytics/gtm'
import { mixpanelTrack } from 'utils/mixpanel'

// auth context
export const AuthContext = createContext()

const useAPI = require('components/lib').useAPI
const Event = require('components/lib').Event
const permissions = require('../permissions')

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user'))
  const [user, setUser] = useState(cache)
  const auth = useAPI(user ? '/api/auth' : null)

  useEffect(() => {
    // update the auth status
    if (!auth.loading && auth.data) {
      auth.data.authenticated ? update(auth.data) : signout()
    }
  }, [auth])

  function signin(res, privatePathToRedirect) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data))
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + res.data.token
      Event('signin')
      gtm.trackLogin()
      mixpanelTrack('Sign in', res.data)

      if (!res.data.verified) return (window.location = '/signup/verify')

      if (!res.data.plan) return (window.location = '/signup/plan')

      if (privatePathToRedirect && privatePathToRedirect !== '/signin') {
        return (window.location = privatePathToRedirect)
      }

      return (window.location = '/')
    }
  }

  async function signout() {
    const userDataString = localStorage.getItem('user')

    if (userDataString) {
      axios({ method: 'delete', url: '/api/auth' })

      const userData = JSON.parse(userDataString)

      mixpanelTrack('Sign out', userData)
      localStorage.clear()

      window.location = '/signin'
    } else {
      console.log('Attempted sign out without being logged in')
      window.location = '/signin'
    }
  }

  async function switchAccount(id) {
    const res = await axios({
      method: 'post',
      url: '/api/auth/switch',
      data: { account: id },
    })

    if (res.data) signin(res)
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key]
        } else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey]
          }
        } else {
          user[key] = data[key]
        }
      }

      localStorage.setItem('user', JSON.stringify(user))
      setUser(user)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: update,
        switchAccount: switchAccount,
        permission: permissions[user?.permission],
      }}
      {...props}
    />
  )
}
