import React from 'react'

export const PrivacyInformation = ({ accessType, contentType }) => {
  let privacyProjectInformation

  switch (accessType) {
    case 'link':
      privacyProjectInformation = `This ${contentType} will be visible only to users who have this link`
      break
    case 'private':
      privacyProjectInformation = `This ${contentType} will be only visible to you`
      break
    case 'only invited':
      privacyProjectInformation = `This ${contentType} will be only visible to users who have a link and are invited`
      break
    default:
      privacyProjectInformation = `This ${contentType} will be visible to all Tokenguard users`
      break
  }

  return <p>{privacyProjectInformation}</p>
}
