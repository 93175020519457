/***
 * HOOK of creator tools nav items
 *
 **********/

import { paths } from "utils/paths"

export function useNavCreatorToolsItems() {

  const navCommunityItems = [
    {
      label: 'Web3 Analytics',
      icon: 'dashboards',
      link: paths.dashboardsPublic,
      related: [
        paths.dashboardsShared,
        paths.dashboardsPersonal,
        '/dashboards',
        ':/dappName/dashboard/:dashboardId',
        '/dashboard/:dashboardId',
      ],
      disabled: false,
    },
    {
      label: 'Dashboard Builder',
      icon: 'plus',
      link: paths.dashboardBuilder,
      related: [
        `/:dappName${paths.dashboardBuilder}/edit/:dashboardId`,
        `/:dappName${paths.dashboardBuilder}/:dashboardId`,
        `${paths.dashboardBuilder}/edit/:dashboardId`,
      ],
      disabled: false,
    }
  ]

  return navCommunityItems
}
