/***
 *
 *   HEADER
 *   Header section with title used in main application (can render children)
 *
 *   PROPS
 *   title: title of the view
 *   children: children to render (optional)
 *
 **********/
import React, { useContext } from 'react'
import { ClassHelper, Container, AuthContext } from 'components/lib'
import { VerifyAccountNotification } from 'features/auth/components/verifyAccountNotification'
import Style from './header.module.scss'

export function Header(props) {
  const authContext = useContext(AuthContext)
  const headerStyle = ClassHelper(Style, {
    borderBottom: props.borderBottom,
  })

  return (
    <header className={headerStyle}>
      <Container className={Style.container}>
        {!authContext.user?.verified && authContext.user?.token ? (
          <VerifyAccountNotification />
        ) : (
          <h1>{props.title}</h1>
        )}
        {props.children}
      </Container>
    </header>
  )
}
