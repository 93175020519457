/***
 *
 *   PROJECT
 *   specific project view
 *
 **********/

import React, { useState, useEffect, useContext } from 'react'
import {
  Animate,
  Container,
  ViewContext,
  Headline,
  TabBar,
  useLocation,
  useNavigate,
  Loader,
} from 'components/lib'
import { fetchProject } from 'utils/fetches/project'
import { DashboardMapper } from 'features/dashboardMapper/dashboardMapper'

export function Project() {
  const location = useLocation()
  const [tabs, setTabs] = useState([])
  const [title, setTitle] = useState()
  const [logo, setLogo] = useState()
  const [description, setDescription] = useState()
  const [loadingDashbords, setLoadingDashbords] = useState(false)
  const [dashboards, setDashboards] = useState([])
  const navigate = useNavigate()
  const viewContext = useContext(ViewContext)
  const [, , id, tag] = location.pathname.split('/')

  useEffect(() => {
    const handlePopState = () => {
      window.history.replaceState({ fromBackButton: true }, '')
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingDashbords(true)
        const data = await fetchProject(id)
        setDescription(data?.description)
        setLogo(data?.logo)
        setTitle(data?.name)

        if (data.project_tabs && data.project_tabs.length > 0) {
          const modifiedTabs = data.project_tabs.map((tab) => ({
            name: tab.name,
            path: `/project/${id}/${tab?.slug}`,
          }))
          setTabs(modifiedTabs)

          const modifiedTag = tag && tag.replace(/%20/g, ' ')
          const tabIndex = data.project_tabs.findIndex(
            (tab) => tab?.slug === modifiedTag
          )
          if (tabIndex === -1) {
            const fromBackButton = window.history.state?.fromBackButton

            if (!tag && fromBackButton) {
              window.history.go(-1) // Go back one more page
              return
            }

            if (!fromBackButton) {
              navigate(`/project/${id}/${data.project_tabs[0].slug}`, {
                state: { fromBackButton: false },
              })
            }
            setDashboards(data.project_tabs[0].dashboards)
          } else {
            setDashboards(data.project_tabs[tabIndex].dashboards)
          }
        } else {
          setDashboards([])
        }
        setLoadingDashbords(false)
      } catch (err) {
        setLoadingDashbords(false)
        viewContext && viewContext.handleError(err)
      }
    }

    fetchData()
  }, [id, tag])

  useEffect(() => {
    const handlePopState = () => {
      window.history.replaceState({ fromBackButton: true }, '')
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  return (
    <Animate type="pop">
      <Container marginBottom8>
        <Headline title={title} subtitle={description} logo={logo} />
      </Container>
      <Container marginBottom24>
        {tabs?.length > 0 && <TabBar underlyingLine tabs={tabs} />}
      </Container>
      {dashboards?.length > 0 && <DashboardMapper dashboards={dashboards} />}
      {dashboards?.length === 0 && !loadingDashbords && (
        <Container flex>No dashboards</Container>
      )}
      {loadingDashbords && <Loader />}
    </Animate>
  )
}
