/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import React, { useContext, useEffect, useState } from 'react'
import {
  AppLayout,
  RedesignAppLayout,
  DashboardLayout,
  AuthLayout,
  HomeLayout,
  useLocation,
  RoutingHistoryContext,
  BuilderLayout,
} from '../lib'
import { mixpanelTrackPage } from 'utils/mixpanel'
import { AuthContext } from '../lib'

export function View(props) {
  // layouts
  const layouts = {
    app: AppLayout,
    redesignApp: RedesignAppLayout,
    dashboard: DashboardLayout,
    builder: BuilderLayout,
    home: HomeLayout,
    auth: AuthLayout,
  }
  const { push } = useContext(RoutingHistoryContext)
  const location = useLocation()
  const authContext = useContext(AuthContext)

  // useState to hold userId, ensuring it has a stable reference
  const [userId, setUserId] = useState(
    authContext.user ? authContext.user.user_id : null
  )

  // useEffect to update userId when authContext.user changes
  useEffect(() => {
    setUserId(authContext.user ? authContext.user.user_id : null)
  }, [authContext.user])

  // useEffect for handling page tracking and potential redirection
  useEffect(() => {
    if (props.layout !== 'auth') {
      push({ path: location.pathname, type: props.access })
    }

    // Call mixpanelTrackPage with current pathname and userId
    mixpanelTrackPage(location.pathname, userId)
  }, [location.pathname, userId, props.layout, props.access]) // Removed `push` from dependencies

  // set title & layout
  if (props.title) {
    document.title = props.title
  }
  let Layout = props.layout ? layouts[props.layout] : AppLayout

  if (!props.display) return false

  return (
    <Layout title={props.title} data={props.data}>
      {props.display}
    </Layout>
  )
}
