import React, { useState, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Responsive, WidthProvider } from 'react-grid-layout'
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"

import {
  DropdownNew,
} from 'components/lib'
import { Icon } from 'components/icon/Icon'
import { DashboardContentContext } from 'app/contexts/DashboardContentContext'

import { BlockButtonContext } from 'features/builders/shared/contexts/BlockButtonContext'
import { BlockTextContext } from 'features/builders/shared/contexts/BlockTextContext'
import { BlockChartContext } from 'features/builders/shared/contexts/BlockChartContext'
import { useVerifiedDashboardFilters } from 'features/dashboard/hooks/useVerifiedDashboardFilters'
import { LockedVisualization } from 'features/builders/dashboardBuilder/components/LockedVisualization'
import Style from './DesignContent.module.css'

const ResponsiveGridLayout = WidthProvider(Responsive)

export const DesignContent = () => {
  const {
    dashboardElements,
    dashboardId,
    setDashboardElements,
    setDashboardLayout,
    dashboardLayout,
    dashboardFilters,
    dashboardTheme,
    dashboardDappId
  } = useContext(DashboardContentContext)
  const [searchParams, _setSearchParams] = useSearchParams()
  const { verifiedFilters } = useVerifiedDashboardFilters(dashboardFilters, searchParams, dashboardId)
  const [elevatedZIndexId, setElevatedZIndexId] = useState<string | undefined>()
  
  const blockButtonContext = useContext(BlockButtonContext)
  const blockTextContext = useContext(BlockTextContext)
  const blockChartContext = useContext(BlockChartContext)

  if (!blockButtonContext) {
    throw new Error('Block button context must be used in Provider')
  }

  if (!blockTextContext) {
    throw new Error('Block text context must be used in Provider')
  }

  if (!blockChartContext) {
    throw new Error('Block chart context must be used in Provider')
  }

  const { setBlockButtonId } = blockButtonContext
  const { setBlockTextId } = blockTextContext
  const { setBlockChartId } = blockChartContext

  const removeElement = (elementId: string) => {
    const layoutElementsToRemove = dashboardLayout.filter(item => item.i !== elementId)
    const dashboardElementsToRemove = dashboardElements.filter(item => item.i !== elementId)

    setDashboardElements(dashboardElementsToRemove)
    setDashboardLayout(layoutElementsToRemove)
  }

  const optionsVisualization = [
    {
      label: 'Edit',
      action: (id: string) => setBlockChartId(id)
    },
    {
      label: 'Delete',
      action: (id: string) => removeElement(id)
    },
  ]

  const elevateZIndex = (id: string) => {
    setElevatedZIndexId(id)
  }

  const optionsButton = [
    {
      label: 'Edit',
      action: (id: string) => setBlockButtonId(id)
    },
    {
      label: 'Delete',
      action: (id: string) => removeElement(id)
    },
  ]

  const optionsText = [
    {
      label: 'Edit',
      action: (id: string) => setBlockTextId(id)
    },
    {
      label: 'Delete',
      action: (id: string) => removeElement(id)
    },
  ]

  const onLayoutChange = (layout: any, layouts: any) => {
    setDashboardLayout(layout)
  }

  return (
    <div className={Style['design-content']}>
      {dashboardLayout.length > 0 && dashboardElements.length > 0 ? (
      <ResponsiveGridLayout
        isDraggable
        isRearrangeable
        isResizable
        layout={dashboardLayout}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 2, xs: 2, xxs: 1 }}
        onLayoutChange={onLayoutChange}
        rowHeight={10}
        draggableHandle={`.${Style['move-wrapper']}`}
      >
        {dashboardLayout.map((item) => {
          const element = dashboardElements.find(element => element.i === item.i)
          if (element?.type !== 'text' && element?.type !== 'button') {
            return (
              <div
                key={item.i}
                data-grid={item}
                className={elevatedZIndexId === item.i && Style['z-index-elevated']}
                role="button"
                onClick={() => elevateZIndex(item.i)}
              >
                <div className={`${Style['move-wrapper']}`}>
                  <LockedVisualization
                    dashboardId={dashboardId}
                    element={element}
                    elementHeight={item.h}
                    filters={verifiedFilters}
                    dashboardTheme={dashboardTheme}
                    dappId={dashboardDappId}
                  />
                </div>
                <div className={Style['item-more']}>
                  <DropdownNew
                    position="bottom-right"
                    options={optionsVisualization}
                    id={item.i}
                  >
                    <Icon name="more" width={16} height={16} />
                  </DropdownNew>
                </div>                    
              </div>
            )
          } else if (element?.type === 'text') {
            return (
              <div
                key={item.i}
                data-grid={item}
                className={elevatedZIndexId === item.i && Style['z-index-elevated']}
                role="button"
                onClick={() => elevateZIndex(item.i)}
              >
                <div className={Style['move-wrapper']}>
                  <div
                    dangerouslySetInnerHTML={{ __html: element.text }}
                    style={{ color: dashboardTheme?.textColor, fontFamily: dashboardTheme?.font }}
                  />
                </div>
                <div className={Style['btn-more']}>
                  <DropdownNew
                    position="bottom-right"
                    options={optionsText}
                    id={item.i}
                  >
                    <Icon name="more" width={16} height={16} />
                  </DropdownNew>
                </div>
              </div>
            )
          } else if (element?.type === 'button') { 
            return (
              <div
                key={item.i}
                data-grid={item}
                className={elevatedZIndexId === item.i && Style['z-index-elevated']}
                role="button"
                onClick={() => elevateZIndex(item.i)}
              >
                <div className={Style['btn-wrapper']}>
                  {element.text}
                </div>
                <div className={Style['btn-more']}>
                  <DropdownNew
                    position="bottom-right"
                    options={optionsButton}
                    id={item.i}
                  >
                    <Icon name="more" width={16} height={16} />
                  </DropdownNew>
                </div>
              </div>
            )
          }
        })}
      </ResponsiveGridLayout>
      ) : null}
    </div>
  )
}
