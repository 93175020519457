/***
 *
 *   SIGN UP STEP 2
 *   Verify email address
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan - not available for now
 *
 **********/

import React from 'react'
import { Animate, Modal } from 'components/lib'
import { VerifyAccount } from 'features/auth/verifyAccount'

export function SignupVerification(_props) {
  return (
    <Animate type="pop">
      <Modal title="Verify your account" redirect>
        <VerifyAccount />
      </Modal>
    </Animate>
  )
}
