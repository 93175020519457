import React from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import {
  SankeyChart,
} from 'echarts/charts'
import {
  CanvasRenderer,
} from 'echarts/renderers'

echarts.use([
  SankeyChart,
  CanvasRenderer,
])

export const CustomSankey = ({
  data,
}) => {
  const option = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove'
    },
    series: {
      type: 'sankey',
      height: 300,
      nodeAlign: 'justify',
      nodeWidth: 20,
      left: 40,
      right: 40,
      nodeGap: 40,
      orient: 'horizontal',
      draggable: false,
      data: data.data,
      links: data.links,
      emphasis: {
        focus: 'adjacency'
      },
    }
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{ width: '100%', height: 350 }}
    />
   )
}