/***
 *
 *   MODALHEADER
 *
 *   PROPS
 *   title: title of header
 *
 **********/

import React, { useContext } from 'react'
import {
  ViewContext,
  Line,
  Icon,
  useNavigate,
  RoutingHistoryContext,
} from 'components/lib'
import Style from './modalHeader.module.scss'

export const ModalHeader = ({ title, redirect }) => {
  const context = useContext(ViewContext)
  const navigate = useNavigate()
  const { routingHistory } = useContext(RoutingHistoryContext)
  const lastPath = routingHistory[routingHistory.length - 1]
  const beforeLastPath = routingHistory[routingHistory.length - 2]

  return (
    <>
      <div className={Style.header}>
        <h1 className={Style.title}>{title}</h1>
        <button
          onClick={() => {
            context.modal.hide(true)
            if (redirect) {
              if (routingHistory.length > 2 && lastPath.access === 'private') {
                navigate(`${beforeLastPath.path}`)
              } else if (
                routingHistory.length > 1 &&
                lastPath.access !== 'private'
              ) {
                navigate(`${lastPath.path}`)
              } else {
                navigate('/')
              }
            }
          }}
        >
          <Icon name="exit" color="gray600" width="16" height="16" />
        </button>
      </div>
      <Line />
    </>
  )
}
