/***
 *
 *   GROWTH INDEX DAPPS OVERVIEW
 *
 **********/

import React, {
  useState,
  useEffect,
  useContext,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Loader,
  Typography,
  Paginate,
  ViewContext
} from 'components/lib'
import { paths } from 'utils/paths'

import { DappTable } from 'features/gi/dapp/giDappOverview/components/dappTable'
import { GiBannersContainer } from 'features/gi/shared/components/giBannersContainer/giBannersContainer'
import { GiLegend } from 'features/gi/shared/components/giLegend/giLegend'
import { NewsBanner } from 'features/gi/shared/components/newsBanner/newsBanner'
import { RankingBanner } from 'features/gi/shared/components/rankingBanner/RankingBanner'
import { GiDappOverviewFilters } from 'features/gi/dapp/giDappOverview/components/giDappOverviewFilters'
import { CardOfBanners } from 'features/gi/shared/components/cardOfBanners/CardOfBanners'
import { useDappOverviewFilters } from 'features/gi/dapp/giDappOverview/hooks/useDappOverviewFilters'
import { fetchGiDappsOverview } from 'features/gi/dapp/giDappOverview/utils/fetches'

export function GiDappOverview() {
  const [isGiDappsDataLoading, setIsGiDappsDataLoading] = useState()
  const [giDappsData, setGiDappsData] = useState()
  const [totalRecords, setTotalRecords] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    giSortValue,
    selectedBlockchain,
    blockchainsOptions,
    verifiedPageSize,
    verifiedBlockchainParam,
    verifiedPage
  } = useDappOverviewFilters()
  const viewContext = useContext(ViewContext)

  useEffect(() => {
    if (verifiedPageSize && verifiedPage && selectedBlockchain && giSortValue && blockchainsOptions && verifiedBlockchainParam) {
      const getGiBlockchainsData = async () => {
        try {
          setIsGiDappsDataLoading(true)
          const fetchedGrowthIndexChainData = await fetchGiDappsOverview(verifiedPageSize, verifiedPage, giSortValue, selectedBlockchain?.slug)
          setGiDappsData(fetchedGrowthIndexChainData.data)
          setTotalRecords(fetchedGrowthIndexChainData.totalDappCount)
          setIsGiDappsDataLoading(false)
        } catch (err) {
          setIsGiDappsDataLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
      getGiBlockchainsData()
    }
  }, [
    selectedBlockchain,
    blockchainsOptions,
    verifiedBlockchainParam,
    verifiedPageSize,
    verifiedPage,
    giSortValue
  ])

  const handleCurrentPage= (currentPage) => {
    searchParams.delete('page')
    searchParams.append('page', currentPage)
    setSearchParams(searchParams)
  }

  return (
    <>
      <CardOfBanners
        bgColor="dapp-color"
        className="mt24"
      >
        <Typography
          tag="h1"
          text="dApp Overview"
          weight="semi-bold"
          size="l"
          color="gray900"
          testId="page-title"
        />
        <GiBannersContainer>
          <GiLegend />
          <RankingBanner
            iconName="tgDashboards"
            text="Ecosystem Overview"
            link={paths.giEcosystemOverview}
          />
          <NewsBanner />
        </GiBannersContainer>
      </CardOfBanners>
      <GiDappOverviewFilters
        giSortValue={giSortValue}
        selectedBlockchain={selectedBlockchain}
        blockchainsOptions={blockchainsOptions}
        verifiedBlockchainParam={verifiedBlockchainParam}
        verifiedPageSize={verifiedPageSize}
        verifiedPage={verifiedPage}
      />
      {!isGiDappsDataLoading && giDappsData?.length > 0 ? (
        <DappTable
          data={giDappsData}
        />
      ) : null}
      {!isGiDappsDataLoading && giDappsData?.length === 0 ? (
        <div className="min-height relative">
          <Typography
            tag="p"
            text="There is currently no data available."
            weight="medium"
            size="m"
            color="gray900"
            className="align-xy"
          />
        </div>
      ) : null}
      {!isGiDappsDataLoading && totalRecords ? (
        <div className="align-center mt24 mb24">
          <Paginate
            totalRecords={totalRecords} 
            limit={verifiedPageSize}
            currentPage={verifiedPage}
            onChange={currentPage => handleCurrentPage(currentPage)}
          />
        </div>
      ) : null}
      {isGiDappsDataLoading && <div className="min-height relative"><Loader /></div>}
    </>
  )
}
