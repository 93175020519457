import React from 'react'

import Style from './TopBar.module.css'

export const TopBar = () => {

  return (
    <div className={Style['topbar']}>
    </div>
  )
}