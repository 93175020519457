import { HomePage } from 'views/website/Home'
import { Contact } from 'views/website/contact'

const Routes = [
  {
    path: '/',
    view: HomePage,
    layout: 'redesignApp',
    title: 'Patterns - User Activity of Top DeFi & Crypto Apps',
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact',
  },
]

export default Routes
