import { logger } from 'utils/logger'

// helper function for constructing tailwind classes
export function ClassHelper(styles, props, debug) {
  let str = ''

  if (typeof styles === 'object') {
    if (styles.base) str += styles.base // always apply base styles

    if (Object.keys(props).length) {
      Object.keys(props).forEach((prop) => {
        if (prop === 'className' && props[prop]) {
          // always apply className and base
          str = str.trim()
          str += ` ${props[prop]}`
        } else if (
          props[prop] &&
          Object.prototype.hasOwnProperty.call(styles, prop)
        ) {
          if (debug) logger.info('styles', prop, props[prop], styles[prop])

          str = str.trim()
          str += ` ${styles[prop]}`
        }
      })
    }
  }

  if (debug) logger.info('final.class', str.trim())

  return str.trim()
}
