/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/

import React from 'react'
import { HomeNav, Footer, useLocation, Page, useMobile } from 'components/lib'
import Style from './home.module.scss'

export function HomeLayout(props) {
  const location = useLocation()
  const isMobile = useMobile()
  const transparent = location?.pathname === '/' ? true : false

  return (
    <Page>
      <main className={Style.home}>{<props.children {...props.data} />}</main>
    </Page>
  )
}
