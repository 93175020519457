import React, {
  useState,
  useEffect,
  useRef,
  ReactNode
} from 'react'
import clsx from 'clsx'

import {
  Line,
  Typography
} from 'components/lib'
import { TOptionOfDropdown } from 'types/optionOfDropdown'
import { Icon } from 'components/icon/Icon'

import Style from './DropdownNew.module.css'

type TDropdownProps = {
  size?: 'medium';
  position?: 'bottom' | 'left' | 'right' | 'bottom-left' | 'bottom-right';
  options?: Array<TOptionOfDropdown>;
  id?: string;
  children: ReactNode;
  title?: string;
  content?: ReactNode;
}

export const DropdownNew = ({
  options,
  id,
  children,
  title,
  position,
  size,
  content
}: TDropdownProps) => {
  const [_clickedOutside, setClickedOutside] = useState(false)
  const [isOpenDropDown, setOpenDropDown] = useState(false)
  const myRef = useRef(null)

  const dropDownStyle = clsx(
    Style['list'],
    position && Style[position],
    size && Style[size]
  )

  const handleOptionClick = (action: (id: string) => void) => {
    setOpenDropDown(false)
    action(id)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (
      e.target instanceof HTMLElement &&
      !myRef?.current?.contains(e.target)
    ) {
      setClickedOutside(true)
      setOpenDropDown(false)
    }
  }

  const handleClickInside = () => setClickedOutside(false)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  return (
    <div className="relative" ref={myRef} onClick={handleClickInside}>
      <div
        className={Style['wrapper']}
        onClick={() => setOpenDropDown(!isOpenDropDown)}
      >
        {children}
      </div>

      {isOpenDropDown && (
        <div className={dropDownStyle}>
          {title && (
            <>
              <Typography
                size="m"
                tag="p"
                text={title}
              />
              <Line className="mt8 mb8" />
            </>
          )}
          {options && options.length > 0 ? (
            <ul>
              {options.map(({
                label,
                type,
                action,
                logo,
                icon
              }) => {
                return (
                  <li
                    onClick={() => handleOptionClick(action)}
                    key={label}
                    className={
                      type ? `${Style[type]} ${Style.option}` : Style.option
                    }
                  >
                    {logo && <img src={logo} alt={`logo ${label}`} />}
                    {icon && <Icon width="12" height="12" name={icon} />}
                    <p>{label}</p>
                  </li>
                )
              })}
            </ul>
          ) : null}
          {content && content}
        </div>
      )}

      {/* <div className={Style.content}>
        {content}
      </div> */}
    </div>
  )
}
