/***
 *
 *   GI DAPP BREAKDOWN DASHBOARD PAGE
 *
 **********/

import React from 'react'

import {
  Animate,
  MainContentContainer,
} from 'components/lib'

import { GiDappBreakdownDashboard } from 'features/gi/dapp/giDappBreakdownDashboard/giDappBreakdownDashboard'

export function GiDappBreakdownPage() {

  return (
    <Animate type="pop">
      <MainContentContainer>
        <GiDappBreakdownDashboard />
      </MainContentContainer>
    </Animate>
  )
}
