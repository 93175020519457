import React, { ReactNode } from 'react'
import clsx from 'clsx'

import Style from './CardOfBanners.module.css'

type TCardOfBannersProps = {
  bgColor: 'ecosystem-color' | 'dapp-color';
  children: ReactNode
}

export const CardOfBanners = ({
  children,
  bgColor
}: TCardOfBannersProps) => {
  const cardStyle = clsx(
    Style['section'],
    Style[bgColor]
  )

  return (
    <section className={cardStyle}>
      {children}
    </section>
  )
}
