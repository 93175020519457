import React, {
  useState,
  useEffect,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  useNavigate,
  ButtonNew,
  CustomSelect,
  CustomSelectNew,
  Icon,
  Image,
  Typography,
} from 'components/lib'
import { paths } from 'utils/paths'

import { useBasicDappsData } from 'features/gi/shared/hooks/useBasicDappsData'
import Style from './DappBasicFilters.module.scss'

export const DappBasicFilters = ({
    selectedDapp,
    selectedDappsToCompare,
    verifiedDappSegment,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
    selectedBlockchains
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { basicDappsData } = useBasicDappsData()
  const [isFiltersReadyToDisplay, setIsFiltersReadyToDisplay] = useState(false)
  const [blockchainsOptionsOfDapp, setBlockchainsOptionsOfDapp] = useState()
  const navigate = useNavigate()

  const handleDisplayingBlockchainButton = (selectedBlockchains) => {
    if (!selectedBlockchains || selectedBlockchains.length === 0 || selectedBlockchains.length > 2) {
      return null
    }
  
    if (selectedBlockchains.length === 1 || selectedBlockchains.length === 2) {
      return (
        <div className={Style['inputs-container']}>
          <Typography
            tag="p"
            text="View:"
            size="s"
            color="gray900"
            weight="medium"
          />
          {selectedBlockchains.map(item => {
            return (
              <ButtonNew
                key={item.slug}
                color="white"
                moveTo={`${paths.giDappOverview}?blockchain=${item.slug}`}
              >
                <img
                  src={item.logo}
                  alt={`${item.slug} logo`}
                  height="16"
                  width="16"
                />
                <Typography
                  tag="span"
                  text={item.name}
                  size="s"
                  color="gray900"
                  weight="medium"
                />
                <Icon
                  name="diagonalArrow"
                  height={12}
                  width={12}
                  color="gray700"
                />
              </ButtonNew>
            )})}
        </div>
      )
    }
  }
  

  const handleDappFilter = (option) => {
    navigate(`/app/${option.value}/analytics`)
  }

  const handleBlockchainFilter = (option) => {
    const optionsValuesStr = option.map(option => option.value).join(',')
    searchParams.delete('blockchains')
    if (optionsValuesStr) {
      searchParams.append('blockchains', optionsValuesStr)
    }
    setSearchParams(searchParams)
  }

  const dappsOptions = basicDappsData.filter(item => item.name !== selectedDapp?.name).map(item => {
    return (
      {
        label: item.name,
        value: item.slug,
        icon: item.icon,
        network: item?.network || 'Mainnet',
        name: item.name,
        id: item.id,
      }
    )
  })

  useEffect(() => {
    if (verifiedDappSegment) {
      setIsFiltersReadyToDisplay(true) 
    }

    if (selectedDapp) {
      const onlyMainnetBlockchains = selectedDapp.blockchains.filter(item => item.network === 'Mainnet')
      const modifiedBlockchains = onlyMainnetBlockchains.map((item) => {
        return (
          {
            label: item.name,
            value: item.slug,
            icon: item.logo,
            name: item.name,
            slug: item.slug
          }
        )
      })
      setBlockchainsOptionsOfDapp(modifiedBlockchains)
    }
  }, [
    verifiedDappSegment,
    verifiedDateRangeParam,
    verifiedCompareWithParam,
    selectedDappsToCompare,
    selectedDapp
  ])

  return (
    <>
      {isFiltersReadyToDisplay && (
        <section className={Style.section}>
          <div className={Style['inputs-container']}>
            <CustomSelectNew
              id="Dapp"
              isSearchable
              isClearable
              prefix="dApp:"
              name="dapp"
              color="green500"
              defaultValue={{
                value: verifiedDappSegment,
                label: selectedDapp.name,
                icon: selectedDapp.icon
              }}
              options={dappsOptions}
              change={handleDappFilter}
              size="medium"
            />
            {blockchainsOptionsOfDapp && blockchainsOptionsOfDapp.length > 1 ? (
              <CustomSelect
                isSearchable={false}
                isMulti
                name="Blockchain"
                prefix="Blockchain:"
                classNamePrefix="react-select-multi-with-icon"
                value={
                  selectedBlockchains ? 
                  selectedBlockchains.map(item => ({
                    label: item.name,
                    value: item.slug,
                    icon: item.logo
                  })) 
                : null}
                placeholder="Blockchain"
                options={blockchainsOptionsOfDapp}
                change={handleBlockchainFilter}
                size="medium"
              />
            ) :  null}
            {blockchainsOptionsOfDapp && blockchainsOptionsOfDapp.length === 1 ? (
              <CustomSelectNew
                id="blockchain"
                isSearchable
                isClearable
                prefix="Blockchain:"
                name="blockchain"
                value={{
                  value: blockchainsOptionsOfDapp[0].value,
                  label: blockchainsOptionsOfDapp[0].label,
                  icon: blockchainsOptionsOfDapp[0].icon
                }}
                options={blockchainsOptionsOfDapp}
                change={handleBlockchainFilter}
                disabled
                size="medium"
              />
            ) :  null}
          </div>
          {handleDisplayingBlockchainButton(selectedBlockchains)}
        </section>
      )}
    </>
  )
}
