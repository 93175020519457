import axios from 'axios'
import settings from 'settings.json'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchDapp = async ( bodyRequest ) => {
  const response = await axios.post(`${serverURL}/api/dapp-analytics/dapp`,
    bodyRequest
  )
  const data = response.data

  return data
}

export const fetchGetDapp = async ( id: string ) => {
  const response = await axios.get(`${serverURL}/api/dapp-analytics/dapp/${id}`)
  const data = response.data

  return data
}

export const fetchStartDappIndexing = async ( bodyRequest ) => {
  const response = await axios.post(`${serverURL}/api/dapp-analytics/start-indexer`,
    bodyRequest
  )
  const data = response.data

  return data
}

export const fetchStopDappIndexing = async ( bodyRequest ) => {
  const response = await axios.post(`${serverURL}/api/dapp-analytics/stop-indexer`,
    bodyRequest
  )
  const data = response.data

  return data
}
