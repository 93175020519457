const GTM_EVENTS = {
  LOGIN: 'LOGIN',
  SIGN_UP: 'SIGN_UP',
  CHAT_STARTED: 'CHAT_STARTED',
}

const pushToDataLayer = (eventData) => {
  if (window.dataLayer) {
    window.dataLayer.push(eventData)
  } else {
    console.warn('Google Tag Manager dataLayer not found.')
  }
}

const trackLogin = () => {
  pushToDataLayer({ event: GTM_EVENTS.LOGIN })
}

const trackSignUp = (customerEmail, customerId, method) => {
  pushToDataLayer({
    event: GTM_EVENTS.SIGN_UP,
    eventProps: {
      customerEmail,
      customerId,
      method,
    },
  })
}

const trackChatStart = () => {
  pushToDataLayer({ event: GTM_EVENTS.CHAT_STARTED })
}

export const gtm = {
  trackLogin,
  trackSignUp,
  trackChatStart,
}
