import React, {
  useState,
  useContext
} from 'react'

import {
  Breadcrumbs,
  Loader,
  useMobile,
  AuthContext,
  useNavigate,
  AddDappModalContext,
} from 'components/lib'
import { useBlockchains } from 'hooks/useBlockchains'
import { paths } from 'utils/paths'
import { breadcrumbs } from 'utils/constans'
import { TDapp } from 'types/dapp'

import { TableOfDapps } from 'features/dapps/addDapp/components/TableOfDapps'
import { AddDappTopBar } from 'features/dapps/addDapp/components/AddDappTopBar'
import { useDapps } from 'features/dapps/addDapp/hooks/useDapps'
import { NoDappContent } from 'features/dapps/addDapp/components/NoDappContent'
import { DappSettingsModal } from 'features/dapps/addDapp/components/DappSettingsModal'
import Style from './AddDapp.module.css'


export const AddDapp = () =>  {
  const { isDisplayingAddDappModal, setIsDisplayingAddDappModal } = useContext(AddDappModalContext)
  const breadcrumbsItems = [
    breadcrumbs.home,
    {
      key: 'add-dapp',
      url: paths.dashboardBuilder,
      name: 'Dashboard Builder',
    }
  ]
  const [previewDapp, setPreviewDapp] = useState<TDapp | undefined>()
  const { dapps, isLoadingDapps } = useDapps()
  const { blockchains } = useBlockchains(`?dappanalytics=true`)
  const isMobile = useMobile()
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  if (isDisplayingAddDappModal && !authContext?.user) {
    navigate('/signin')
  }

  return (
    <>
      {isDisplayingAddDappModal && blockchains && authContext?.user && (
        <DappSettingsModal
          setIsDisplayingAddDappModal={setIsDisplayingAddDappModal}
          blockchains={blockchains}
          previewDapp={previewDapp}
          setPreviewDapp={setPreviewDapp}
        />
      )}
      <div className={Style['add-dapp']}>
        <Breadcrumbs items={breadcrumbsItems} />
        <AddDappTopBar
          setIsDisplayingAddDappModal={setIsDisplayingAddDappModal}
          setPreviewDapp={setPreviewDapp}
        />
        <div className={Style['table-mobile-container']}>
          {!isLoadingDapps && dapps && dapps?.length > 0 ? (
            <TableOfDapps
              dapps={dapps}
              blockchains={blockchains}
              setPreviewDapp={setPreviewDapp}
              setIsDisplayingAddDappModal={setIsDisplayingAddDappModal}
            />
          ) : null}
          {!isLoadingDapps && dapps && dapps?.length === 0 ? (
            <NoDappContent
              setIsDisplayingAddDappModal={setIsDisplayingAddDappModal}
              page="add-dapp"
            />
          ) : null}
          {isLoadingDapps && <Loader />}
        </div>
      </div>
    </>
  )
}