/***
 *
 *   INFORMATION AUTH COLUMN
 *   column for image and some description
 *
 **********/

import React from 'react'

import appPreview from 'assets/images/app-preview.png'

import Style from './informationAuthColumn.module.scss'

import {
  BRAND_NAME
} from 'utils/constans'

export const InformationAuthColumn = ({ headline, text }) => (
  <div className={Style.column}>
    <img
      src={appPreview}
      alt={`${BRAND_NAME} app preview`}
      className={Style.image}
    />
    <p className={Style.headline}>{headline}</p>
    <p className={Style.text}>{text}</p>
  </div>
)
