/***
 *
 *   GROWTH INDEX BLOCKCHAINS DATA CONTEXT
 *
 **********/

import React, { useState, createContext } from 'react'

export const GiBlockchainsDataContext = createContext()

export function GiBlockchainsDataProvider({ children }) {
  const [giBlockchainsDataContext, setGiBlockchainsDataContext] = useState([])

  return (
    <GiBlockchainsDataContext.Provider value={{ giBlockchainsDataContext, setGiBlockchainsDataContext }}>
      {children}
    </GiBlockchainsDataContext.Provider>
  )
}
