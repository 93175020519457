/***
 *
 *   FOOTER
 *
 **********/
import React, { useContext } from 'react'
import {
  Animate,
  Container,
  Link,
  AuthContext,
  Line,
  SocialMedia,
  Logo,
  useMobile,
} from 'components/lib'
import Style from './footer.module.scss'

export function Footer() {
  const context = useContext(AuthContext)
  const isMobile = useMobile()

  if (isMobile) {
    return (
      <Animate type="slideup">
        <footer className={Style.footer}>
          <Container flex>
            <nav className={Style.nav}>
              <Link url="/" text="Home" className={Style.link} color="dark" />
              {context.user?.token ? null : (
                <>
                  <Link url="/signin" text="Sign in" className={Style.link} />
                  <Link url="/signup" text="Sign up" className={Style.link} />
                </>
              )}
              <Link url="/contact" text="Contact" className={Style.link} />
              <Link
                url="https://tokenguard.io/terms-of-use"
                text="Terms of Service"
                className={Style.link}
              />
              <Link
                url="https://tokenguard.io/privacy-policy"
                text="Privacy Policy"
                className={Style.link}
              />
            </nav>
          </Container>
          <Container>
            <Line marginTop marginBottom />
          </Container>
          <Container flex>
            <Logo dark />
            <SocialMedia />
          </Container>
          <Container>
            <div className={Style.copyright}>
              {`Copyright © Tokenguard ${new Date().getFullYear()}`}
            </div>
          </Container>
        </footer>
      </Animate>
    )
  }

  return (
    <Animate type="slideup">
      <footer className={Style.footer}>
        <Container flex>
          <Logo dark />
          <SocialMedia />
        </Container>
        <Container>
          <Line smallMarginTop smallMarginBottom />
        </Container>
        <Container flex>
          <nav className={Style.nav}>
            <Link url="/" text="Home" className={Style.link} color="dark" />
            {context.user?.token ? null : (
              <>
                <Link url="/signin" text="Sign in" className={Style.link} />
                <Link url="/signup" text="Sign up" className={Style.link} />
              </>
            )}
            <Link url="/contact" text="Contact" className={Style.link} />{' '}
            <Link
              url="https://tokenguard.io/terms-of-use"
              text="Terms of Service"
              className={Style.link}
            />
            <Link
              url="https://tokenguard.io/privacy-policy"
              text="Privacy Policy"
              className={Style.link}
            />
          </nav>
          <div className={Style.copyright}>
            {`Copyright © Tokenguard ${new Date().getFullYear()}`}
          </div>
        </Container>
      </footer>
    </Animate>
  )
}
