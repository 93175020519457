/***
 *
 *   TG BREAKDOWN DASHBOARD PAGE
 *
 **********/

import React from 'react'

import {
  Animate,
  MainContentContainer,
} from 'components/lib'

import { GiEcosystemBreakdownDashboard } from 'features/gi/ecosystem/giEcosystemBreakdownDashboard/giEcosystemBreakdownDashboard'

export function BreakdownPage() {

  return (
    <Animate type="pop">
      <MainContentContainer>
        <GiEcosystemBreakdownDashboard />
      </MainContentContainer>
    </Animate>
  )
}
