import React from 'react'
import { Navigate } from 'react-router-dom'
import { RequireVerification } from 'features/auth/requireVerification'

const permissions = require('./permissions')

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check user exists
  const user = JSON.parse(localStorage.getItem('user'))
  const path = window.location.pathname
  localStorage.setItem('privatePathToRedirect', JSON.stringify(path))

  if (user?.token) {
    if (permissions[user.permission][props.permission]) {
      // user has no plan
      if (!user.plan && path !== '/account/profile' && path !== '/signup/plan')
        return <Navigate replace to="/signup/plan" />

      // user is not verified
      if (
        !user.verified &&
        path !== '/account/profile' &&
        path !== '/signup/verify'
      )
        return <RequireVerification />

      // user is good
      return props.children
    }
  }

  // user is not authenticated
  return <Navigate replace to="/signup" />
}

