export function transformToSingleValueFormat(obj, metric) {
  let objToReturn = {}
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let metricArray = obj[key][metric]
      let lastItem = metricArray ? metricArray[metricArray.length - 1]?.value : null
      objToReturn[key] = {
        currentValue: lastItem
      }
    }
  }
  return objToReturn
}