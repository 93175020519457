/***
 *
 *   SMALL HEADLINE
 *
 **********/

import React from 'react'
import Style from './smallHeadline.module.scss'
import { ClassHelper } from 'components/class'

export const SmallHeadline = (props) => {
  const smallHeadlineStyle = ClassHelper(Style, props)

  return (
    <div className={smallHeadlineStyle}>
      <h1 className={Style.title}>{props.title}</h1>
      {props.subtitle && <h2 className={Style.subtitle}>{props.subtitle}</h2>}
    </div>
  )
}
