import React, {
  useState,
  useEffect,
} from 'react'

import { 
  Line,
  Container,
  Typography
} from 'components/lib'

import { Dashboard } from 'features/dashboard/Dashboard'

export const DashboardMapper = ({ dashboards }) => {
  const [dashboardsToDisplay, setDashboardsToDisplay] = useState([])

  useEffect(() => {
    setDashboardsToDisplay(dashboards)
  }, [dashboards])

  return (
    <div className="relative min-height">
      {dashboardsToDisplay.length > 0 ? (
        dashboardsToDisplay.map(
          (dashboard, index) => {
            return (
              <div key={`${dashboard.dashboard.id}${index}`}>
                <Dashboard dashboardData={dashboard} />
                <Container>
                  {dashboardsToDisplay.length !== index + 1 && <Line />}
                </Container>
              </div>
            )
          }
        )
      ) : (
        <Typography
          text="No Data"
          size="s"
          weight="regular"
          tag="p"
        />
      )}
    </div>
  )
}
