import React from 'react'

import { ClassHelper } from 'components/class'

import Style from './container.module.scss'

export const Container = (props) => {
  const containerStyle = ClassHelper(Style, {
    base: true,
    flex: props.flex,
    className: props.className,
    marginTop16: props.marginTop16,
    noPadding: props.noPadding,
    marginBottom8: props.marginBottom8,
    marginBottom24: props.marginBottom24,
  })

  return <div className={containerStyle}>{props.children}</div>
}
