import axios from 'axios'

import settings from 'settings'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchSankeyData = async (dappSlug, blockchains) => {
  let paramsToSend = {
    dapp: dappSlug
  }
  if (blockchains && blockchains.length > 0) {
    const blockchainsToParam = blockchains.map(blockchain => blockchain.slug)
    paramsToSend.chains = blockchainsToParam
  }

  const response = await axios.get(
    `${serverURL}/api/dapp/user-flow`,
    {
      params: paramsToSend
    }
  )

  return response.data
}