import { DashboardPage } from 'views/dashboard/dashboardPage'
import { ShareDashboardPage } from 'views/dashboard/shareDashboardPage'

const Routes = [
  {
    path: '/dashboard/:id',
    view: DashboardPage,
    layout: 'dashboard',
    title: 'Dashboard',
  },
  {
    path: '/dashboard/tokenguard/:id',
    view: DashboardPage,
    layout: 'dashboard',
    title: 'Dashboard',
  },
  {
    path: '/dashboard/share/:accessLink',
    view: ShareDashboardPage,
    layout: 'dashboard',
    title: 'Dashboard',
  },
]

export default Routes
