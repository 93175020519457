/***
 *
 *   SIDEBAR
 *
 **********/

import React, {
  useState,
  useContext
} from 'react'
import {
  Logo,
  Icon,
  AppNav,
  useMobile,
  Line,
  IconButton,
  AuthContext,
  AddDappModalContext,
  UserNav,
  AuthNav,
  RulesNav,
  ButtonNew,
  useNavigate
} from 'components/lib'
import { paths } from 'utils/paths'
import { useNavCreatorToolsItems } from './hooks/useNavCreatorToolsItems'
import { useNavRankingItems } from './hooks/useNavRankingItems'
import { useNavAboutUsItems } from './hooks/useNavAboutUsItems'
import './sidebar.scss'

export function Sidebar() {
  const isMobile = useMobile()
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { setIsDisplayingAddDappModal } = useContext(AddDappModalContext)
  // state
  const [open, setOpen] = useState(false) // mobile is open
  const [fade, _setFade] = useState('')
  const navRankingItems = useNavRankingItems()
  const navCreatorToolsItems = useNavCreatorToolsItems()
  const navAboutUsItems = useNavAboutUsItems()

  return (
    <aside className={`sidebar sidebar-mobile ${open ? 'open' : 'close'}`}>
      {isMobile && (
        <div className="bar-wrapper">
          <Logo dark />

          <IconButton
            icon={<Icon
              name={open ? 'hamburgerClose' : 'hamburgerOpen'}
              width="20"
              height="20"
              color="primary500"
            />}
            onClick={() => setOpen(!open)}
          />
        </div>
      )}

      {!isMobile && <Logo />}

      <section className="menu">
        <div>
          <AppNav
            title="Ranking"
            items={navRankingItems}
            setOpen={setOpen}
            className={`menu ${fade}`}
          />
          <Line color="gray200" />
          <AppNav
            title="Creator Tools"
            items={navCreatorToolsItems}
            setOpen={setOpen}
            className={`menu ${fade}`}
          />
          <ButtonNew
            size="medium"
            onClick={() => {
              setOpen(false)
              setIsDisplayingAddDappModal(true)
            }}
            moveTo={paths.dashboardBuilder}
            startIcon={<Icon name="plus" color="green500" width="16" height="16" />}
            fullWidth
            color="green500"
            variant="outline"
          >
            Add your dApp
          </ButtonNew>
        </div>
        <div className="bottom-part">
          <AppNav
            items={navAboutUsItems}
            setOpen={setOpen}
            className={`menu ${fade}`}
          />
          {!authContext.user?.token ? (
            <>
              <AuthNav />
              <RulesNav />
            </>
          ) : (
            <>
              <UserNav
                user={authContext.user}
                setOpen={setOpen}
              />
              <RulesNav />
            </>
          )}
        </div>
      </section>
    </aside>
  )
}
