/***
 *
 *   LINE
 *
 **********/

import React from 'react'
import clsx from 'clsx'

import Style from './Line.module.css'

type TLineProps = {
  color?: 'gray100' | 'secondary600';
  className?: string;
  showOr?: boolean;
}

export const Line = ({
  className,
  color,
  showOr
}: TLineProps) => {
  const lineStyle = clsx(
    Style['line'],
    className,
    color && Style[color],
  )

  if (showOr) {
    return <span className={`${Style.or} ${className}`}>or</span>
  }

  return (
    <hr className={lineStyle} />
  )
}

