import React, { forwardRef, Ref } from 'react'
import clsx from 'clsx'
import { Label } from 'components/lib'
import Style from './TextInput.module.css'

interface TextInputProps {
  value: string;
  name: string;
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  change: (e: React.ChangeEvent<HTMLInputElement>) => void;
  blur?: () => void;
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  className?: string;
  disabled?: boolean;
}

export const TextInputNew = forwardRef<HTMLInputElement, TextInputProps>(function MyInput(props, ref) {
  const { value, change, blur, name, label, placeholder, defaultValue, size, className, disabled } = props
  const textInputStyle = clsx(
    Style['input'],
    size ? Style[size] : Style['medium'],
    className && className
  )

  return (
    <div className={Style['input-container']}>
      {label && <Label text={label} forInput={name} />}
      <input
        type="text"
        autoComplete="off"
        name={name}
        value={value || ''}
        defaultValue={defaultValue}
        className={textInputStyle}
        placeholder={placeholder || ''}
        onChange={change}
        onBlur={blur}
        ref={ref}
        disabled={disabled}
      />
    </div>
  )
})
