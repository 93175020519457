import { palette } from 'utils/constans'

import othersIcon from 'features/gi/dapp/giDappBasicDashboard/assets/icons/others.svg'
import { NO_USER_ACTIVITY } from 'features/gi/dapp/giDappBasicDashboard/utils/constans'

function findIndexOfNoneUser(position, links) {
  
  for (let i = 0; i < links.length; i++) {
    if (position === 'left' && links[i].source.name === NO_USER_ACTIVITY) {
      return i
    }

    if (position === 'right' && links[i].target.name === NO_USER_ACTIVITY) {
      return i
    }
  }

  return -1
}

function modifyLinks(position, links) {
  const noneUserIndex = findIndexOfNoneUser(position, links)
  let sumOfRemainingValues = 0
  let othersObj
  let result = []

  if (position === 'left') {
    othersObj = {
      source: {
        name: 'Others',
        icon: othersIcon,
        slug: 'others',
      },
      target: links[0].dapp,
      dapp:  links[0].dapp,
      chain: links[0].chain,
      position: 'left'
    }
  } else {
    othersObj = {
      source: links[0].dapp,
      target: {
        name: 'Others',
        icon: othersIcon,
        slug: 'others',
      },
      dapp:  links[0].dapp,
      chain: links[0].chain,
      position: 'right'
    }
  }


  if (noneUserIndex === -1) {
    links.sort((a, b) => b.value - a.value)
    const topLinks = links.slice(0, 4)
    const remainingLinks = links.slice(4)
    for (const link of remainingLinks) {
      sumOfRemainingValues += link.value
    }
    if (sumOfRemainingValues > 0) {
      topLinks[4] = {
        ...othersObj,
        value: sumOfRemainingValues,
      }
    }

    result = topLinks
  } else {
    let linksWithoutNone
    const noneObj = links[noneUserIndex]
    links.sort((a, b) => b.value - a.value)
    if (position === 'left') {
      linksWithoutNone = links.filter(item => item.source.name !== NO_USER_ACTIVITY)
    } else {
      linksWithoutNone = links.filter(item => item.target.name !== NO_USER_ACTIVITY)
    }
    result = linksWithoutNone.slice(0,3)
    const remainingLinks = linksWithoutNone.slice(3)
    const resultLastIndex = result.length - 1
    result[resultLastIndex + 1] = noneObj
    for (const link of remainingLinks) {
      sumOfRemainingValues += link.value
    }
    if (sumOfRemainingValues > 0) {
      result[resultLastIndex + 2] = {
        ...othersObj,
        value: sumOfRemainingValues,
      }
    }
  }

  return result
}

function generateSankeyPoints(data, suffix, isMobile) {

  const result = []
  data.forEach((item) => {        
    let pointName
    let pointIcon

    if (item.position === 'left') {
      pointName = item.source.name
      pointIcon = item.source.icon
    } else if (item.position === 'right') {
      pointName = `${item.target.name}${suffix}`
      pointIcon = item.target.icon
    } 

    result.push({
      name: pointName,
      label: {
        position: item.position,
        formatter: [
          '{logo|}'
        ].join('\n'),
        rich: {
          logo: {
            backgroundColor: {
              image: pointIcon,
            },
            height: 32,
            width: 32
          },
        },
      },
    })
  })

  result.push({
    name: data[0].dapp.name,
    label: {
      position: 'inside',
      formatter: [
        '{logo|}'
      ].join('\n'),
      backgroundColor: 'white',
      borderRadius:  6,
      borderWidth: 2,
      borderColor: palette.green500,
      width: isMobile ? 48 : 140,
      height: 180,
      rich: {
        logo: {
          backgroundColor: {
            image: data[0].dapp.icon
          },
          height: isMobile ? 32 : 56,
          width: isMobile ? 32 : 56,
          align: 'center',
          lineHeight: 180,
        },
      },
    },
  })

  return result
}

const generateSankeyLinks = (data, suffix) => {

  return data.map(item => {

    if (item.position === 'left') {
      return (
        {
          source: item.source?.name ? item.source.name : `Others`,
          target: item.dapp.name,
          value: item.value,
        }
      )
    } else {
      return (
        {
          source: item.dapp.name,
          target: item.target?.name ? `${item.target.name}${suffix}` : `Others${suffix}`,
          value: item.value,
        }
      )
    }

  })
}

export const convertToSankeyFormat = (data, suffix, isMobile) => {
  const leftLinks = []
  const rightLinks = []
  data.forEach(item => {
    if (item.target?.slug === item.dapp?.slug || item.source?.slug !== item.dapp?.slug) {
      leftLinks.push({...item, position: 'left'})
    } else {
      rightLinks.push({...item, position: 'right'})
    }
  })

  const groupedBestLeft = modifyLinks('left', leftLinks)
  const groupedBestRight = modifyLinks('right', rightLinks)

  return {
    data: generateSankeyPoints([...groupedBestLeft, ...groupedBestRight], suffix, isMobile),
    links: generateSankeyLinks([...groupedBestLeft, ...groupedBestRight], suffix),
  }
}

export function replaceNoneWithNoOtherActivity(data) {
  return data.map(item => ({
      ...item,
      source: {
          ...item.source,
          name: item.source.name === 'None' ? NO_USER_ACTIVITY : item.source.name
      },
      target: {
          ...item.target,
          name: item.target.name === 'None' ? NO_USER_ACTIVITY : item.target.name
      }
  }));
}