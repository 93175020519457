/***
 *
 *   AYTHNAV
 *   include login link
 *
 **********/

import React from 'react'
import {
  NavLink,
} from 'react-router-dom'

import {
  Icon,
  Typography
} from 'components/lib'
import Style from './AuthNav.module.css'

export const AuthNav = ({
  user
}) => (
  <NavLink to="/signin">
    <div className={Style['button-auth-nav']}>
      <Icon name="user-rounded" width="36" height="36" />
      <Typography
        text="Log in / Sign up"
        tag="p"
        size="s"
        weight="semi-bold"
        color="primary500"
      />
    </div>
  </NavLink>
)
