/***
 *
 *  SHAREPROJECT
 *   specific project view
 *
 **********/

import React, { useState, useEffect, useContext } from 'react'
import {
  Animate,
  Container,
  ViewContext,
  Headline,
  TabBar,
  useLocation,
  useNavigate,
} from 'components/lib'
import { DashboardMapper } from 'features/dashboardMapper/dashboardMapper'
import { fetchShareProject } from 'utils/fetches/project'

export function ShareProject() {
  const location = useLocation()
  const [tabs, setTabs] = useState([])
  const [logo, setLogo] = useState()
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [dashboards, setDashboards] = useState([])
  const navigate = useNavigate()
  const viewContext = useContext(ViewContext)
  const [, , , accessLink, tag] = location.pathname.split('/')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchShareProject(accessLink)
        setDescription(data?.description)
        setLogo(data?.logo)
        setTitle(data?.name)
        if (data.project_tabs && data.project_tabs.length > 0) {
          const modifiedTabs = data.project_tabs.map((tab) => ({
            name: tab.name,
            path: `/project/share/${accessLink}/${tab.slug}`,
          }))
          setTabs(modifiedTabs)

          const modifiedTag = tag && tag.replace(/%20/g, ' ')
          const tabIndex = data.project_tabs.findIndex(
            (tab) => tab.slug === modifiedTag
          )
          if (tabIndex === -1) {
            navigate(
              `/project/share/${accessLink}/${data.project_tabs[0].slug}`
            )
            setDashboards(data.project_tabs[0].dashboards)
          } else {
            setDashboards(data.project_tabs[tabIndex].dashboards)
          }
        } else {
          setDashboards([])
        }
      } catch (err) {
        viewContext && viewContext.handleError(err)
      }
    }

    fetchData()
  }, [accessLink, tag])

  return (
    <Animate type="pop">
      <Container marginBottom24>
        <Headline title={title} subtitle={description} logo={logo} />
        {tabs?.length > 0 && <TabBar underlyingLine tabs={tabs} />}
      </Container>
      {dashboards?.length > 0 && <DashboardMapper dashboards={dashboards} />}
      {dashboards?.length === 0 && <Container flex>Tab is empty</Container>}
    </Animate>
  )
}
