import React, { useState, forwardRef, Ref } from 'react'
import clsx from 'clsx'

import { Label } from 'components/lib'

import Style from './NumberInput.module.css'

interface NumberInputProps {
  value: number,
  name: string,
  label?: string,
  placeholder?: string,
  change: (e: React.ChangeEvent<HTMLInputElement>) => void,
  blur?: () => void,
  ref?: Ref<HTMLInputElement>,
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge',
  disabled?: boolean,
  prefix?: string
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(function MyInput(props, ref) {
  const {
    value,
    change,
    blur,
    name,
    label,
    placeholder,
    size,
    disabled,
    prefix,
  } = props
  const [innerValue, setInnerValue] = useState<string | number>(value || '')

  const numberInputStyle = clsx(
    Style['input'],
    size ? Style[size] : Style['medium'],
    prefix && Style['prefix-padding'],
  )

  return (
    <div className={Style['number-input-container']}>
      {label && <Label text={label} forInput={name} />}

      <div className={Style['input-prefix-container']}>
        {prefix && <span className={Style['prefix']}>{prefix}</span>}
        <input
          type="number"
          autoComplete="off" 
          name={name}
          className={numberInputStyle}
          placeholder={placeholder || ''}
          onBlur={blur}
          ref={ref}
          disabled={disabled}
          defaultValue={value}
          value={innerValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInnerValue(e.target.value)
            change(e)
          }}
        />
      </div>
    </div>
  )
})