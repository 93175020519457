import React from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  CustomSelectNew,
} from 'components/lib'

import { usePublicDashboardsFilters } from 'features/dashboardsGrid/public/hooks/usePublicDashboardsFilters'
import Style from './PublicDashboardsFilters.module.css'

export const PublicDashboardsFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    selectedBlockchain,
    blockchainsOptions,
    dappsOptions,
    selectedDapp
  } = usePublicDashboardsFilters()

  const handleDappFilter = (option) => {
    searchParams.delete('dapp')
    searchParams.append('dapp', option.value)
    setSearchParams(searchParams)
  }

  const handleBlockchainFilter = (option) => {
    searchParams.delete('blockchain')
    searchParams.append('blockchain', option.value)
    setSearchParams(searchParams)
  }

  return (
    <section className={Style.section}>
      <CustomSelectNew
        id="dapp"
        isSearchable
        isClearable
        prefix="dApp:"
        name="dapp"
        value={{
          value: selectedDapp.slug,
          label: selectedDapp.name,
          icon: selectedDapp.icon
        }}
        options={dappsOptions}
        change={handleDappFilter}
        size="medium"
      />
      {blockchainsOptions && selectedBlockchain ? (
        <CustomSelectNew
          id="blockchain"
          isSearchable
          isClearable
          prefix="Blockchain:"
          name="blockchain"
          value={{
            value: selectedBlockchain.slug,
            label: selectedBlockchain.name,
            icon: selectedBlockchain.logo || selectedBlockchain.icon
          }}
          options={blockchainsOptions}
          change={handleBlockchainFilter}
          size="medium"
        />
      ) :  null}
    </section>
  )
}
