/***
 *
 *   MESSAGE
 * 
 * props:
 * withoutBuildInIcon: boolean value, set if don't want default icon
 * closable: boolean, set true/false if want close 'x' or not
 * type: for type of message error/info/warning/success
 * children
 * buttonLink - string type, set redirect link
 * buttonText - button text
 * text - string - text value
 * title - string for title value

 **********/

import React, { useState } from 'react'
import { Button, IconLib, useNavigate, ClassHelper } from 'components/lib'
import Style from './message.module.scss'

export function Message(props) {
  const navigate = useNavigate()

  // state
  const [closed, setClosed] = useState(false)
  const type = props.type || 'info'

  if (closed) return false

  const icon = {
    info: 'info',
    success: 'check',
    warning: 'alert-triangle',
    error: 'alert-octagon',
  }

  const color = {
    info: 'blue',
    success: 'green',
    warning: 'orange',
    error: 'red',
  }

  // style
  const messageStyle = ClassHelper(Style, {
    message: true,
    [`${color[type]}Bg`]: true,
    className: props.className,
  })

  const titleStyle = ClassHelper(Style, {
    title: true,
    [`${color[type]}Text`]: true,
  })

  return (
    <div className={messageStyle}>
      {props.withoutBuildInIcon && (
        <IconLib
          className={Style.icon}
          size={30}
          color={color[type]}
          image={icon[type]}
        />
      )}

      {props.closable && (
        <Button
          icon="x"
          position="absolute"
          className={Style.close}
          action={() => setClosed(true)}
        />
      )}

      <section className={Style.content}>
        {props.title && <h1 className={titleStyle}>{props.title}</h1>}

        {props.text && <p>{props.text}</p>}

        {props.children && props.children}

        {props.buttonLink && (
          <Button
            className={Style.btn}
            color={color[type]}
            text={props.buttonText}
            action={() => navigate(props.buttonLink)}
          />
        )}
      </section>
    </div>
  )
}
