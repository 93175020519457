import React, { useContext, useEffect, useState } from 'react'
import { AuthContext, Form, SocialSignin, Line } from 'components/lib'
import { gtm } from 'utils/analytics/gtm'
import { useLocation } from 'react-router-dom'

export const SignUpContainer = () => {
  const authContext = useContext(AuthContext)
  const location = useLocation()
  const [privatePathToRedirect, setPrivatePathToRedirect] = useState(
    JSON.parse(localStorage.getItem('privatePathToRedirect'))
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const emailFromUrl = params.get('email')
    const dashboardIdFromUrl = params.get('dashboardId')
    const projectIdFromUrl = params.get('projectId')

    if (emailFromUrl) {
      formData.email.value = emailFromUrl
    }
    if (dashboardIdFromUrl) {
      formData.dashboard_id.value = dashboardIdFromUrl
      setPrivatePathToRedirect(`dashboard/${dashboardIdFromUrl}`)
    }
    if (projectIdFromUrl) {
      formData.project_id.value = projectIdFromUrl
      setPrivatePathToRedirect(`project/${projectIdFromUrl}`)
    }
  }, [location])

  const formData = {
    name: {
      label: 'Your Name',
      type: 'text',
      placeholder: 'Enter your name',
      required: true,
      errorMessage: 'Please enter your first name',
    },
    email: {
      label: 'Email Address',
      type: 'email',
      placeholder: 'Enter your email',
      required: true,
      value: '',
    },
    password: {
      label: 'Password',
      type: 'password',
      placeholder: '*******',
      required: true,
      complexPassword: true,
    },
    confirm_password: {
      type: 'hidden',
      value: null,
    },
    dashboard_id: {
      type: 'hidden',
      value: '',
    },
    project_id: {
      type: 'hidden',
      value: '',
    },
  }

  return (
    <>
      <SocialSignin network={['google']} signup />
      <Line showOr />
      <Form
        data={formData}
        url="/api/account"
        method="POST"
        buttonText="Create account"
        callback={(res) => {
          if (res.status === 200) {
            gtm.trackSignUp(res.data.email, res.data.user_id, 'credentials')
            authContext.signin(res, privatePathToRedirect)
          }
        }}
      />
    </>
  )
}
