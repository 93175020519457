import { Account } from 'views/account'
import { Profile } from 'views/account/profile'
import { Password } from 'views/account/password'

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Your Account',
  },
  {
    path: '/account/profile',
    view: Profile,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Your Profile',
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Change Password',
  },
]

export default Routes
