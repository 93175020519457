import React, { useState, useEffect, useContext, useRef } from 'react'
import { toast } from 'react-toastify'

import { ViewContext } from 'components/lib'
import {
  addEmailsToDashboard,
  fetchDashboard,
  fetchInvitedDashboardUsers,
  updateDashboardAccess,
} from 'utils/fetches/dashboard'
import {
  addEmailsToProject,
  fetchInvitedProjectUsers,
  fetchProject,
  updateProjectAccess,
} from 'utils/fetches/project'
import { processEmailList } from 'utils/helpers'
import { logger } from 'utils/logger'

import PrivacySettings from 'features/shareContent/privacySettings/privacySettings'

export const ShareContent = ({ contentId, contentType }) => {
  const [id, setId] = useState()
  const [accessType, setAccessType] = useState()
  const [accessLink, setAccessLink] = useState()
  const [emailList, setEmailList] = useState('')
  const [lookerId, setLookerId] = useState('')
  const [_emailArray, setEmailArray] = useState([])
  const [validInput, setValidInput] = useState(true)
  const [fetchedData, setFetchedData] = useState(null)
  const [usersData, setUsersData] = useState([])
  const startAccessType = useRef(null)
  const startId = useRef(null)
  const viewContext = useContext(ViewContext)

  const handleEmailListChange = (name, value, _valid) => {
    if (value !== undefined) {
      setEmailList(value)
    } else {
      logger.error('Value is not passed correctly:', value)
    }
  }

  const handleInviteClick = async () => {
    if (emailList !== undefined) {
      const { validEmails, invalidEmails } = processEmailList(emailList)

      if (invalidEmails.length > 0) {
        setValidInput(false)
        logger.error('Invalid emails:', invalidEmails)
      } else {
        setEmailArray(validEmails)
        setValidInput(true)

        const addEmailsToContentFunction =
          contentType === 'project' ? addEmailsToProject : addEmailsToDashboard

        try {
          const result = await addEmailsToContentFunction(
            fetchedData.id,
            validEmails
          )
          if (result) setEmailList('')
        } catch (error) {
          setValidInput(false)
        }

        try {
          await fetchUsers(id, contentType)
        } catch (error) {
          viewContext.handleError(error)
        }
      }
    } else {
      logger.error('Email list is not defined')
    }
  }

  const fetchUsers = async (id, contentType) => {
    const fetchUsersFunction =
      contentType === 'project'
        ? fetchInvitedProjectUsers
        : fetchInvitedDashboardUsers
    const users = await fetchUsersFunction(id)
    setUsersData(users)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchFunction =
          contentType === 'project' ? fetchProject : fetchDashboard
        const data = await fetchFunction(contentId)
        if (contentType === 'dashboard') {
          const { dashboard } = data
          setAccessType(dashboard?.access_type)
          setId(dashboard?.id)
          startAccessType.current = dashboard?.access_type
          startId.current = dashboard?.id
          setAccessLink(dashboard?.access_link)
          setFetchedData(dashboard)
          const users = await fetchInvitedDashboardUsers(dashboard.id)
          setUsersData(users)
        } else {
          setAccessType(data?.access_type)
          setId(data?.id)
          setLookerId(data?.looker_id)
          startAccessType.current = data?.access_type
          startId.current = data?.id
          setAccessLink(data?.access_link)
          setFetchedData(data)
          const users = await fetchInvitedProjectUsers(data.id)
          setUsersData(users)
        }
      } catch (err) {
        viewContext.handleError(err)
      }
    }

    fetchData()
  }, [contentType, contentId])

  const handlePrivacySettingsSelect = async (option) => {
    try {
      const updateAccessFunction =
        contentType === 'project' ? updateProjectAccess : updateDashboardAccess
      const data = await updateAccessFunction(id, option.value)
      setAccessLink(data.access_link)
      setAccessType(data.access_type)
      toast.success(`Your ${contentType} privacy settings have been changed`)
    } catch (err) {
      logger.error(err)
      viewContext.handleError('Sorry, something goes wrong...')
    }
  }

  return (
    <PrivacySettings
      accessType={accessType}
      contentType={contentType}
      usersData={usersData}
      handlePrivacySettingsSelect={handlePrivacySettingsSelect}
      contentId={contentId}
      accessLink={accessLink}
      emailList={emailList}
      handleEmailListChange={handleEmailListChange}
      handleInviteClick={handleInviteClick}
      fetchUsers={fetchUsers}
      id={id}
      lookerId={lookerId}
      validInput={validInput}
    />
  )
}
