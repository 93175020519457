/***
 *
 *   TOGGLE
 *
 **********/
import React from 'react'
import Toggle from 'react-toggle'
import clsx from 'clsx'

import './Toggle.css'

type TTogggleProps = {
  defaultChecked: boolean;
  onChange: () => void;
  icons: boolean;
  color: 'white' | 'primary';
}

export const CustomToggle = ({
  defaultChecked,
  onChange,
  icons,
  color
}: TTogggleProps) => {
  const toogleStyle = clsx(
    color && `react-toggle-${color}`,
  )

  return (
    <Toggle
      defaultChecked={defaultChecked}
      onChange={onChange}
      icons={icons}
      className={toogleStyle}
    />
  )
}