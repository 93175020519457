import React from 'react'

import Style from './CheckboxNew.module.css'

export const CheckboxNew = ({ label, value, onChange }) => {
  return (
    <label className={Style['checkbox-container']}>
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  )
}