/***
 *
 *   TG BREAKDOWN DASHBOARD
 *
 **********/


import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react'
import axios from 'axios'

import {
  ViewContext,
  Loader,
  useLocation,
  Breadcrumbs
} from 'components/lib'
import {
  useSeoPage
} from 'hooks/useSeoPage'
import {
  endpoint,
  paths
} from 'utils/paths'
import { breadcrumbs } from 'utils/constans'

import { COLLECTION_NAME } from 'features/gi/shared/utils/constans'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import { FiltersSection } from 'features/gi/ecosystem/shared/components/filtersSection/filtersSection'
import { ChartSection } from 'features/gi/ecosystem/shared/components/chartSection/chartSection'
import {
  DAILY_ACTIVE_USERS,
  TRANSACTIONS,
  TVL,
  SMART_CONTRACTS,
  FEES_PAID,
  MARKET_CAP,
  TG_GROWTH_INDEX,
} from 'features/gi/ecosystem/shared/utils/constans'
import cumulativeIcon from 'features/gi/shared/assets/icons/cumulative.svg'
import {
  transformToSingleValueFormat,
  transformToTimelineFormat,
} from 'features/gi/ecosystem/giEcosystemBreakdownDashboard/utils/helpers'
import { TgGrowthIndexBreakdownSection } from 
  'features/gi/ecosystem/giEcosystemBreakdownDashboard/components/tgGrowthIndexBreakdownSection/tgGrowthIndexBreakdownSection'

export function GiEcosystemBreakdownDashboard() {
  const {
    verifiedBlockchainSegment,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
    verifiedParamsStr,
    selectedBlockchainsToCompare,
    selectedBlockchain,
  } = useVerifiedFiltersParams()
  const [chartsData, setChartsData] = useState([
    { name: TG_GROWTH_INDEX, slug: 'tg_growth_index', value: 0, round: 0},
    { name: TVL, slug: 'tvl', value: 0, round: 2, formatValue: true, prefixValue: '$' },
    { name: TRANSACTIONS, slug: 'transactions', value: 0, round: 0, formatValue: true },
    { name: SMART_CONTRACTS, slug: 'smart_contracts', value: 0, round: 0, formatValue: true },
    { name: FEES_PAID, slug: 'fees_paid', value: 0, round: 2, formatValue: true, prefixValue: '$' },
    { name: MARKET_CAP, slug: 'market_cap', value: 0, round: 2, formatValue: true, prefixValue: '$'},
    { name: DAILY_ACTIVE_USERS, slug: 'active_users', value: 0, round: 0, formatValue: true },
  ])
  const [timelineDataLoadingStatus, setTimelineDataLoadingStatus] = useState({
    [TG_GROWTH_INDEX]: true,
    [TVL]: false,
    [TRANSACTIONS]: false,
    [SMART_CONTRACTS]: false,
    [FEES_PAID]: false,
    [MARKET_CAP]: false,
    [DAILY_ACTIVE_USERS]: false,
  })
  const location = useLocation()
  useSeoPage({
    title: `${selectedBlockchain?.blockchain?.name || ' '} - Charts`,
    description: `View ${selectedBlockchain?.blockchain?.name || ' '} crypto charts, explore detailed chain analysis metrics & monitor activity`,
    keywords: [`${selectedBlockchain?.blockchain?.name || 'dApp'} chart`, 'crypto activity', 'chain analysis'],
    ogTitle: `${selectedBlockchain?.blockchain?.name || ' '} - Charts`,
    ogDescription: `View ${selectedBlockchain?.blockchain?.name || ' '} crypto charts, explore detailed chain analysis metrics & monitor activity`,
    ogImage: selectedBlockchain?.blockchain?.logo
    // ogUrl: to set
  })
  const [ , , secondSegment ] = location.pathname.split('/')
  const breadcrumbsItems = [
    breadcrumbs.home,
    breadcrumbs.ecosystemOverview,
    {
      key: 'activity',
      url: `/crypto/${secondSegment}${location.search}`,
      name: `${selectedBlockchain?.blockchain?.name || ''}`
    },
    {
      key: 'charts',
      url: `/crypto/${secondSegment}/charts${location.search}`,
      name: `Charts`
    },
  ]
  const [blockchain, setBlockchain] = useState()
  const [comparison, setComparison] = useState()
  const viewContext = useContext(ViewContext)
  const requestControllerTL = {}
  requestControllerTL[TG_GROWTH_INDEX] = useRef()
  requestControllerTL[DAILY_ACTIVE_USERS] = useRef()
  requestControllerTL[TRANSACTIONS] = useRef()
  requestControllerTL[SMART_CONTRACTS] = useRef()
  requestControllerTL[TVL] = useRef()
  requestControllerTL[FEES_PAID] = useRef()
  requestControllerTL[MARKET_CAP] = useRef()

  useEffect(() => {

    if (verifiedBlockchainSegment && verifiedParamsStr) {

      if (verifiedCompareWithParam && selectedBlockchainsToCompare) {
        let comparisonLogo
        let comparisonName
        if (selectedBlockchainsToCompare.length > 1) {
          comparisonLogo = cumulativeIcon
          comparisonName = COLLECTION_NAME
        } else {
          comparisonLogo = selectedBlockchainsToCompare[0].blockchain.logo
          comparisonName = selectedBlockchainsToCompare[0].blockchain.name
        }
        setComparison({
          name: comparisonName,
          logo: comparisonLogo
        })
      }

      setBlockchain({
        name: selectedBlockchain.blockchain.name,
        logo: selectedBlockchain.blockchain.logo,
      })

      const fetchTimeline = async (name, metric) => {
        try {
          setTimelineDataLoadingStatus(prevState => ({
            ...prevState,
            [name]: true,
          }))
          let url = endpoint.apiGiTimelineData

          if (requestControllerTL[name].current) {
            requestControllerTL[name].current.abort()
          }
    
          requestControllerTL[name].current = new AbortController()
          const signal = requestControllerTL[name].current.signal
          const compare = verifiedCompareWithParam?.split(',') || []
          const postData = {
            chainName: verifiedBlockchainSegment,
            period: verifiedDateRangeParam,
            metric: metric,
            compareWith: compare,
          }
          
          const response = await axios.post(url, postData, {
            signal: signal
          })
          const singleValueFormatresponse = transformToSingleValueFormat(response.data, metric)

          setChartsData(prevState => prevState.map(obj => {
            if (obj.name === name) {
              return {
                ...obj,
                timelineData: response.data,
                singleValueData: singleValueFormatresponse
              }
            } else {
              return obj
            }
          }))
        
          setTimelineDataLoadingStatus(prevState => ({
            ...prevState,
            [name]: false,
          }))
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setTimelineDataLoadingStatus(prevState => ({
              ...prevState,
              [name]: false,
            }))
            viewContext && viewContext.handleError(err)
          }
        }
      }

      fetchTimeline(TG_GROWTH_INDEX, 'tg_growth_index')
      fetchTimeline(TVL, 'tvl')
      fetchTimeline(TRANSACTIONS, 'weekly_txns')
      fetchTimeline(SMART_CONTRACTS, 'cum_deployed_scs')
      fetchTimeline(FEES_PAID, 'weekly_fees')
      fetchTimeline(MARKET_CAP, 'market_cap')
      fetchTimeline(DAILY_ACTIVE_USERS, 'avg_dau')
    }

  }, [verifiedBlockchainSegment, verifiedParamsStr])

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems}  />
      </div>
      {verifiedBlockchainSegment && verifiedParamsStr ? (
        <>
          <TgGrowthIndexBreakdownSection
            tgGrowthIndexTimeline={chartsData[0].timelineData}
            isTgGrowthIndexTimelineLoading={timelineDataLoadingStatus[TG_GROWTH_INDEX]}
            blockchain={blockchain}
            comparison={comparison}
          />
          <FiltersSection />
          {chartsData.slice(1).map(item => {
            return (
              <ChartSection
                key={item.name}
                title={item.name}
                loadingTimelineFlag={timelineDataLoadingStatus[item.name]}
                timelineData={item.timelineData}
                singleValueData={item.singleValueData}
                blockchain={blockchain}
                comparison={comparison}
                round={item?.round}
                prefixValue={item?.prefixValue}
                formatValue={item?.formatValue}
                metric={item.name}
              />
            )
          })}
        </>
      ) : <div className="min-height relative"><Loader /></div>}
    </>
  )
}
