import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import settings from 'settings.json'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { ButtonNew } from 'components/lib'
import { paths } from 'utils/paths'
import { TTheme } from 'types/dashboard/theme'
import { TElement } from 'types/dashboard/element'
import { TLayout } from 'types/dashboard/layout'

import { DashboardTitle } from 'features/builders/dashboardBuilder/components/DashboardTitle'
import { prepareElementsFormatToSend } from 'features/builders/dashboardBuilder/utils/helpers'
import { prepareLayoutFormatToSend } from 'features/builders/dashboardBuilder/utils/helpers'
import Style from './ActionBar.module.css'


const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

type TBodyRequest = {
  title: string;
  elements: TElement;
  layout: TLayout;
  theme: TTheme;
  dappId?: string;
}

export const ActionBar = () => {
  const {
    dashboardElements,
    dashboardLayout,
    dashboardTheme,
    dashboardTitle,
    dashboardId,
    dashboardDappId,
    setDashboardElements,
    setDashboardLayout,
    setDashboardTitle,
    setDashboardTheme,
    setDashboardDappId,
    setDashboardFilters,
    setDashboardId
  } = useContext(DashboardContentContext)
  const navigate = useNavigate()

  const saveDashboard = async () => {
    try {
      const bodyRequest: TBodyRequest = {
        title: dashboardTitle,
        elements: prepareElementsFormatToSend(dashboardElements),
        layout: prepareLayoutFormatToSend(dashboardLayout),
        theme: dashboardTheme,
      }
      if (dashboardDappId) {
        bodyRequest.dappId = dashboardDappId
      }
      await axios.post(`${serverURL}/api/dashboard`, bodyRequest)
      setDashboardElements([])
      setDashboardLayout([])
      setDashboardTheme({})
      setDashboardTitle('Default Dashboard')
      setDashboardId(undefined)
      setDashboardDappId(undefined)
      setDashboardFilters([])
      toast.success('Dashboard saved successfully')
      navigate(paths.dashboardsPersonal)
    } catch (err) {
      toast.error(`We're sorry, the dashboard could not be saved. Please try again later.`)
    }
  }

  const updateDashboard = async () => {
    try {
      await axios.put(`${serverURL}/api/dashboard/${dashboardId}`, {
        title: dashboardTitle,
        elements: prepareElementsFormatToSend(dashboardElements),
        layout: prepareLayoutFormatToSend(dashboardLayout),
        theme: dashboardTheme
      })
      setDashboardElements([])
      setDashboardLayout([])
      setDashboardTheme({})
      setDashboardTitle('Default Dashboard')
      setDashboardId(undefined)
      setDashboardDappId(undefined)
      setDashboardFilters([])
      toast.success('Dashboard updated successfully')
      navigate(paths.dashboardsPersonal)
    } catch (err) {
      toast.error(`We're sorry, the dashboard could not be updated. Please try again later.`)
    }
  }

  return (
    <div className={Style['action-bar']}>
      <DashboardTitle />
      <ButtonNew onClick={dashboardId ? updateDashboard : saveDashboard}>Save Dashboard</ButtonNew>
    </div>
  )
}
