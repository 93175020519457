export const prepareLayoutFormatToSend = (layoutData) => {
  if (layoutData?.length > 0) {
    const layoutWithoutIndex = layoutData.map(element => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { i, _id, ...rest } = element

      return (
        {
          static: true,
          ...rest,
          id: i,
        }
      )
    })

    return layoutWithoutIndex
  }
}

export const prepareElementsFormatToSend = (elementsData) => {
  if (elementsData?.length > 0) {
    const ElementsArrWithIndex = elementsData.map(element => {
      element.id = element.i
      delete element.i
      delete element.data

      return (
        {
          ...element,
          title: element.text || element.title,
        }
      )
    })

    return ElementsArrWithIndex
  }
}

export const prepareLayoutFormat = (layoutData) => {
  if (layoutData?.length > 0) {
    const layoutWithIndex = layoutData.map(element => {
      return (
        {
          ...element,
          i: element.id,
          static: false,
        }
      )
    })

    return layoutWithIndex
  }
}

export const prepareElementsFormat = (elementsData) => {
  if (elementsData?.length > 0) {
    const ElementsArrWithIndex = elementsData.map(element => {
      return (
        {
          ...element,
          i: element.id,
        }
      )
    })

    return ElementsArrWithIndex
  }
}
