import {
  TSingleDappDetails,
  TSmartContractData,
} from "types/dapp"

export const convertToFileSize = (size: number) => {
  const units = ['b', 'kb', 'mb', 'gb', 'tb'];
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024
      unitIndex++
  }

  return `${size?.toFixed(1)}${units[unitIndex]}`
}

export function shortenFileString(filename: string) {
  if (filename?.length > 6) {
    let parts = filename.split('.')
    if (parts.length < 2) {

      return filename
    }
    let name = parts.slice(0, -1).join('.')
    let extension = parts[parts.length - 1]

    if (name.length > 6) {
      let shortenedName = name.slice(0, 3) + '..' + name.slice(-1)
      return shortenedName + '.' + extension
    }
  }

  return filename
}

export function removeFileFieldsFromDappObj(obj: TSingleDappDetails) {
  const newObj = { ...obj }

  if (Array.isArray(newObj.abis)) {
    newObj.abis = newObj.abis.map((item: TSmartContractData) => {
      const newItem = { ...item }
      delete newItem.fileName
      delete newItem.fileSize
      delete newItem.id
      delete newItem.i
      delete newObj.addedBy
      return newItem
    })
  }

  return newObj
}
