import React from 'react'
import Style from './giTooltipInformation.module.scss'

export const GiTooltipInformation = ({ title, information, metrics }) => (
  <div className={Style.container}>
    <p className={Style.title}>{title}</p>
    <p className={Style.paragraph}>{information}</p>
    <ul className={Style.list}>
      {metrics.map((metric, index) => (
      <li key={`${metric}${index}`}>{metric}</li>
      ))}
    </ul>
  </div>
)