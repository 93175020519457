import React from 'react'
import ReactDOMServer from 'react-dom/server'

import {
  CustomGauge,
  CustomTooltip,
  Icon,
  Loader,
  useMobile,
  Typography,
} from 'components/lib'
import { handleGrowthIndexLevel } from 'utils/helpers'
import informations from 'utils/informations.json'
import { INDICATOR_NAME } from 'utils/constans'

import { GaugeLegend } from 'features/gi/ecosystem/shared/components/gaugeLegend/gaugeLegend'
import { GiTooltipInformation } from 'features/gi/shared/components/giTooltipInformation/giTooltipInformation'
import { GaugeLabel } from 'features/gi/shared/components/gaugeLabel/GaugeLabel'
import Style from './gaugeSection.module.scss'

export const GaugeSection = ({
  title,
  data,
  loadingData,
  blockchain,
  comparison
}) => {
  const isMobile = useMobile()

  return (
    <div className={Style.container}>
      {!loadingData && data && ( 
        <>
          {data?.cumulative?.tg_growth_index?.currentValue && comparison ? (
            <div className={Style.comparisonGaugeContainer}>
              <div className={Style.gaugeContainer}>
                <CustomGauge
                  darkMode
                  value={data?.blockchain?.tg_growth_index?.currentValue?.toFixed(0) || 0}
                  level={handleGrowthIndexLevel(data?.blockchain?.tg_growth_index?.currentValue || 0)}
                  thickness="small"
                />
                <GaugeLabel
                  darkMode
                  icon={blockchain.logo}
                />
              </div>
              <div className={Style.gaugeContainer}>
                <CustomGauge
                  darkMode
                  value={
                    comparison ?
                    data.cumulative.tg_growth_index.currentValue?.toFixed(0) || 0
                    :
                    data.cumulative.tg_growth_index.currentValue?.toFixed(0) || 0}
                  level={
                    comparison > 1 ?
                    handleGrowthIndexLevel(data.cumulative.tg_growth_index?.currentValue?.toFixed(0)) || handleGrowthIndexLevel(0)
                    :
                    handleGrowthIndexLevel(data.cumulative.tg_growth_index.currentValue?.toFixed(0)) || handleGrowthIndexLevel(0)}
                  thickness="small"
                />
                <GaugeLabel
                  darkMode
                  icon={comparison.logo}
                />
              </div>
            </div>
          ) : (
            <CustomGauge
              darkMode
              value={data?.blockchain?.tg_growth_index?.currentValue?.toFixed(0) || 0}
              level={handleGrowthIndexLevel(data?.blockchain?.tg_growth_index?.currentValue || 0)}
            />
          )}
        </>
      )}
      {title && (
        <div className={Style.titleContainer}>
          <Typography
            text={title}
            tag="span"
            color="primary500"
            size="xs"
            weight="regular"
          />
          <div
            className="relative"
            data-tooltip-id="tg-growth-index-information"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <GiTooltipInformation
                title={`Ecosystem ${INDICATOR_NAME}`}
                information={informations.ecosystem.gi}
                metrics={informations.ecosystem.giListOfMetrics}
              />
            )}
            data-tooltip-place={isMobile ? "bottom" : "right-start"}
            data-tooltip-position-strategy="fixed"
            data-tooltip-offset={8}
          >
            <Icon
              name="information"
              width="16"
              height="16"
            />
            <CustomTooltip
              id={'tg-growth-index-information'}
              light
            />
          </div>
        </div>
      )}
      {!loadingData && !data && (
        <div className="min-height relative">
          <Typography
            tag="p"
            color="white"
            text="N/A"
            size="xl"
            weight="regular"
            className="align-xy"
          />
        </div>
      )}
      {loadingData && <div className="min-height relative"><Loader /></div>}
      <GaugeLegend />
    </div>
  )
}
