import { z } from 'zod'

export const FilterSchema =  z.object({
  _id: z.string(),
  queryId: z.number().optional(),
  params: z.array(z.string()).optional(),
  name: z.string(),
  // options: z.array(z.string()), // to add
  type: z.string(),
  component: z.string(),
  // TODO set defaultValue
  // defaultValue: z.string(),
  // defaultValue: z.union([z.array(), z.string()]),
  __t: z.string(),
  __v: z.number()
})

export type TFilter = z.infer<typeof FilterSchema>