/***
 *
 *   PAGE
 *   Wrapper component for page content
 *   PROPS
 *   children: children to render
 *
 **********/

import React from 'react'
import Style from './page.module.scss'

export function Page(props) {
  return <div className={Style.page}>{props.children}</div>
}
