/***
 *
 *   USERNAV
 *   include all user settings page
 *
 **********/

import React, {
  useContext,
  Dispatch,
  SetStateAction,
  ReactNode
} from 'react'
import {
  NavLink,
} from 'react-router-dom'

import {
  Icon,
  Image,
  useMobile,
  AuthContext,
  Typography
} from 'components/lib'
import { trimLongString } from 'utils/helpers'
import userDefaultAvatar from 'assets/icons/default-user-avatar.svg'
import { paths } from 'utils/paths'

import Style from './UserNav.module.css'

type TUserNavProps = {
  user: any,
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const UserNav = ({
  user,
  setOpen
}: TUserNavProps) => {
  const authContext = useContext(AuthContext)
  const withDots = true
  const isMobile = useMobile()

  return (
    <section className={Style['user-nav']}>
      <div className={Style['user-card']}>
        <Image source={userDefaultAvatar} />
        <div className={Style['user-information']}>
          <Typography
            text={user.name}
            tag="p"
            size="s"
            weight="semi-bold"
            color="primary500"
          />
          <Typography
            text={!isMobile ? trimLongString(user.email, 16, withDots) : user.email}
            tag="p"
            size="xs"
            weight="regular"
            color="primary500"
          />
        </div>
      </div>
      <div className={Style['user-settings']}>
        <ButtonUserOption
          icon={<Icon name="settings" width="16" height="16" />}
          link={paths.profile}
          setOpen={setOpen}
        />
        <ButtonUserOption
          icon={<Icon name="signOut" width="16" height="16" color="red500" />}
          action={authContext.signout}
          setOpen={setOpen}
        />
      </div>
    </section>
  )
}

type TButtonUserOptionProps = {
  link?: string,
  icon: ReactNode,
  action?: () => void,
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ButtonUserOption = ({
  link,
  icon,
  action,
  setOpen
}: TButtonUserOptionProps) => {

  if (link) {
    return (
      <NavLink
        to={link}
        className={Style['button-user-option']}
        onClick={() => setOpen(false)}
      >
        {icon}
      </NavLink>
    )
  }

  return (
    <div
      onClick={() => {
        setOpen(false)
        action()
      }}
      className={Style['button-user-option']}
    >
      {icon}
    </div>
  )
}