/***
 *
 *   SEARCH
 *   Search input field
 *
 *   PROPS
 *   throttle: throttle the callback execution in ms
 *   action: function executed on change and submit
 *
 **********/

import React, { useState, ReactNode } from 'react'
import clsx from 'clsx'

import Style from './SearchInput.module.css'

type TSearchInputProps = {
  value: string;
  icon: ReactNode;
  size?: 'small';
  placeholder?: string;
  action: (inputValue: string) => void;
}

export const SearchInput = ({
  value,
  icon,
  size,
  placeholder,
  action
}: TSearchInputProps) => {
  const [inputValue, setInputValue] = useState(value || '')
  // const [typing, setTyping] = useState(false)

  const searchStyle = clsx(
    Style['search-input'],
    icon && Style['with-icon'],
    size && Style[size]
  )

  // useEffect(() => {
  //   // throttle typing
  //   if (props.throttle && !typing) {
  //     const onKeyPress = () => {
  //       setTyping(true)
  //       setTimeout(() => {
  //         setTyping(false)
  //       }, props.throttle)
  //     }

  //     window.addEventListener('keydown', onKeyPress)
  //     return () => window.removeEventListener('keydown', onKeyPress)
  //   }
  // }, [props.throttle, typing])

  // useEffect(() => {
  //   // callback when typing throttle done
  //   if (props.throttle && !typing) props.action(value)
  // }, [props, typing, value])

  return (
    <div className={Style['search-input-container']}>
      {icon}
      <input
        type="text"
        className={searchStyle}
        placeholder={placeholder || 'Search'}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          action(e.target.value)
        }}
      />
    </div>
  )
}
