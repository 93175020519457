/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

import React from 'react'
import ThreeDots from './images/three-dots.svg'
// import BallTriangle from './images/ball-triangle.svg'
// import Orbit from './images/orbit.svg'
// import Oval from './images/oval.svg'
import { ClassHelper } from 'components/lib'
import Style from './loader.module.scss'

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, props)

  return (
    <div className={loaderStyle}>
      {/* todo, decide which spinner is better */}
      {/* <img src={Orbit} className={Style.orbit} alt="Orbit Spinner" /> */}
      {/* <img src={BallTriangle} alt="ball triangle Loader" /> */}
      {/* <img src={Oval} alt="dots Loader" /> */}
      <img src={ThreeDots} alt="dots Loader" />
    </div>

  )
}
