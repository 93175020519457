import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useSeoPage } from 'hooks/useSeoPage'
import { paths } from 'utils/paths'
import {
  Breadcrumbs,
  MainContentContainer
} from 'components/lib'
import { breadcrumbs } from 'utils/constans'

import { MyDashboardsGrid } from 'features/dashboardsGrid/myDashboardsGrid'
import { PublicDashboardsGrid } from 'features/dashboardsGrid/public/publicDashboardsGrid'
import { SharedDashboardGrid } from 'features/dashboardsGrid/sharedDashboardsGrid'
import { DashboardHeader } from 'features/shareContent/dashboardHeader/dashboardHeader'

export function DashboardsPage() {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState()
  const [,, tabFromUrl, ..._rest] = location.pathname.split('/')
  useSeoPage({
    title: 'Web3 Analytics | Patterns',
    description: 'Analyze your favourite web3 projects and track multiple events & user behavior. Monitor key metrics and improve revenue thanks to real-time crypto dashboards.',
    keywords: ['web3 analytics', 'crypto dashboards'],
    ogTitle: 'Web3 Analytics | Patterns',
    ogDescription: 'Analyze your favourite web3 projects and track multiple events & user behavior. Monitor key metrics and improve revenue thanks to real-time crypto dashboards.',
    // ogImage: // to do,
    // ogUrl: // to do
  })
  const breadcrumbsItems = [
    breadcrumbs.home,
    {
      key: 'web3-analytics',
      url: paths.dashboardsPublic,
      name: 'Web3 Analytics',
    },
  ]
  const tabs = [
    {
      name: 'Public',
      path: paths.dashboardsPublic,
      onClick: () => setActiveTab('public'),
    },
    {
      name: 'Personal',
      path: paths.dashboardsPersonal,
      onClick: () => setActiveTab('peronal'),
    },
    {
      name: 'Shared with you',
      path: paths.dashboardsShared,
      onClick: () => setActiveTab('shared'),
    },
  ]

  useEffect(() => {
    if (tabFromUrl) {
      setActiveTab(tabFromUrl)
    }  else {
      setActiveTab('public')
    }
  }, [tabFromUrl])

  return (
    <MainContentContainer>
      <Breadcrumbs items={breadcrumbsItems} />
      <DashboardHeader
        tabs={tabs}
        showCreateButton={activeTab === activeTab}
      />
      {(() => {
        switch (activeTab) {
          case 'public':
            return (
              <PublicDashboardsGrid hideCreateButton={true} />
            )
          case 'personal':
            return <MyDashboardsGrid hideCreateButton={true} />
          case 'shared':
            return <SharedDashboardGrid />
          default:
            return null
        }
      })()}
    </MainContentContainer>
  )
}
