/***
 *
 *   SECTION HEADER
 *
 **********/
import React from 'react'
import { ClassHelper } from 'components/lib'
import Style from './headline.module.scss'

export const Headline = ({ title, subtitle, margin, logo, fullWidth, className }) => {
  const headlineStyle = ClassHelper(Style, {
    margin: margin,
    fullWidth: fullWidth,
    className: className
  })

  return (
    <div className={headlineStyle}>
      {logo ? (
        <div className={Style.titleContainer}>
          <img className={Style.logo} src={logo} alt="logo" />
          <h1 className={Style.title}>{title}</h1>
        </div>
      ) : (
        <h1 className={Style.title}>{title}</h1>
      )}
      {subtitle && <h2 className={Style.subtitle}>{subtitle}</h2>}
    </div>
  )
}
