import React from 'react'
import { Label, Error, ClassHelper } from 'components/lib'
import Style from './select.module.scss'

export function Select(props) {
  let options = props.options
  const error = props.errorMessage || 'Please select an option'

  // set the default
  if (!props.default && options?.length) {
    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0)
    options.unshift({ value: 'unselected', label: 'All' })
  }

  function change(e) {
    let value = e ? e.target?.value : 'unselected'
    let valid = undefined

    // validate
    valid = props.required && value === 'unselected' ? false : true
    props.onChange(props.name, value, valid)
    props.callback && props.callback(value)
  }

  const wrapperStyle = ClassHelper(Style, {
    className: props.className,
    success: props.valid === true,
    errorWrapper: props.valid === false,
    warningWrapper: props.warning,
    important: props.important,
    standard: !props.important,
    darkOutline: props.darkOutline,
  })

  const selectStyle = ClassHelper(Style, {
    select: true,
    error: props.valid === false,
    warning: props.warning,
    small: props.small,
    important: props.important,
    standard: !props.important,
    dark: props.darkOutline,
  })

  return (
    <div className={Style.input}>
      <Label
        text={props.label}
        required={props.required}
        for={props.name}
        className={props.Lab}
        capitalize={props.small}
      />

      <div className={wrapperStyle}>
        <select
          className={selectStyle}
          defaultValue={props.defaultValue}
          onChange={(e) => change(e)}
          id={props.name}
        >
          {options?.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>

        {props.valid === false && (
          <Error message={error} className={Style.message} />
        )}
      </div>
    </div>
  )
}
