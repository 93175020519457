import React from 'react'
import { createRoot } from 'react-dom/client'

import { BRAND_NAME } from 'utils/constans'
import { logger } from 'utils/logger'
import App from './app/app'
import './css/global.scss'
import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

const welcome = () => logger.info(`Welcome to ${BRAND_NAME} 🚀`)
welcome('de529c70-eb80-4dfb-9540-5075db7545bf')
