/***
 *
 *   DAPP USERS PAGE
 *
 **********/

import React from 'react'

import {
  Animate,
  MainContentContainer,
} from 'components/lib'

import { EcosystemUsers } from 'features/gi/crm/ecosystemUsers/EcosystemUsers'

export function EcosystemUsersPage() {

  return (
    <Animate type="pop">
      <MainContentContainer>
        <EcosystemUsers />
      </MainContentContainer>
    </Animate>
  )
}